import { createSlice } from "@reduxjs/toolkit";
const initState = JSON.parse(localStorage.getItem("hine"))?.patients ?? {
  patients: [],
};
export const patientsSlice = createSlice({
  name: "patients",
  initialState: initState,
  reducers: {
    setPatients: (state, action) => {
      console.log("Setting patients");
      console.log(action.payload);
      state.patients = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPatients } = patientsSlice.actions;

export default patientsSlice.reducer;

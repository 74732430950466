import jsPDF from "jspdf";
import fapeamLogo from "../main-menu/fapeam.png";
import hineLogo from "../main-menu/hine.png";
import labtamLogo from "../main-menu/labtam.png";
import ufamlogo from "../main-menu/ufam.png";

import moment from "moment";

export default function createPdf(
  name,
  patientData,
  hineData,
  recordDate,
  doctorData
) {
  const sect1 = hineData.hine[0];
  const doc = new jsPDF("p", "pt", "a4", false);
  doc.setFontSize(10);

  doc.addImage(hineLogo, "PNG", 10, 10, 250, 50);

  doc.addImage(ufamlogo, "PNG", 380, 10, 50, 50);
  doc.addImage(fapeamLogo, "PNG", 435, 10, 75, 50);
  doc.addImage(labtamLogo, "PNG", 515, 10, 80, 50);

  const ident = 30;
  const identStep = 10;

  const textInitY = 100;
  const lineJumpStep = 30;
  const lineJumpHalfStep = 20;

  const pontuacao = parseFloat(sect1?.points) || 0;

  const monthsDiff = moment().diff(patientData.birthDate, "months");

  const otima =
    (monthsDiff <= 3 && pontuacao >= 56) ||
    (monthsDiff <= 6 && pontuacao >= 59) ||
    (monthsDiff <= 9 && pontuacao >= 62) ||
    (monthsDiff <= 12 && pontuacao >= 65);

  const textoOtimo =
    "ausência de risco para alteração neuromotora/paralisia cerebral.";
  const textoSubOtimo =
    "predição de risco para alteração neuromotora/paralisia cerebral.";

  let sectionIdent = ident;
  doc.text("INFORMAÇÕES DO PACIENTE:", sectionIdent, textInitY);
  sectionIdent = ident + identStep;
  doc.text(`NOME: ${patientData.name}`, sectionIdent, textInitY + lineJumpStep);
  doc.text(
    `DATA DE NASCIMENTO: ${moment(patientData.birthDate).format("YYYY-MM-DD")}`,
    sectionIdent,
    textInitY + lineJumpStep + lineJumpHalfStep
  );

  doc.text(
    `DATA DA AVALIAÇÃO: ${moment(recordDate).format("YYYY-MM-DD HH:MM")}`,
    sectionIdent,
    textInitY + lineJumpStep + lineJumpHalfStep * 2
  );

  doc.text(
    `AVALIADOR(A): ${doctorData.name} ${doctorData.email}`,
    sectionIdent,
    textInitY + lineJumpStep + lineJumpHalfStep * 3
  );

  doc.text(
    `IDADE GESTACIONAL: ${
      patientData?.idadeGestacional || "Desconhecido"
    } (semanas)`,
    sectionIdent,
    textInitY + lineJumpStep + lineJumpHalfStep * 4
  );

  doc.text(
    `IDADE CRONOLÓGICA: ${patientData?.idadeCronologica || "Desconhecido"}`,
    sectionIdent,
    textInitY + lineJumpStep + lineJumpHalfStep * 5
  );

  doc.text(
    `IDADE CORRIGIDA: ${patientData?.idadeCorrigida || "Desconhecido"}`,
    sectionIdent,
    textInitY + lineJumpStep + lineJumpHalfStep * 6
  );

  sectionIdent = ident;
  doc.text(
    `RESUMO DA AVALIAÇÃO:`,
    sectionIdent,
    textInitY + lineJumpStep + lineJumpHalfStep * 6 + lineJumpStep * 2
  );

  sectionIdent = ident + identStep;
  doc.text(
    `ESCORE GLOBAL: ${sect1?.points || 0}`,
    sectionIdent,
    textInitY + lineJumpStep + lineJumpHalfStep * 6 + lineJumpStep * 3
  );

  sectionIdent = ident + identStep * 2;

  doc.text(
    `Seção I: ${sect1?.points || 0}`,
    sectionIdent,
    textInitY + lineJumpStep + lineJumpHalfStep * 6 + lineJumpStep * 4
  );

  sectionIdent = ident + identStep * 3;
  doc.text(
    `Função de Nervos Cranianos: ${sect1.subsections[0]?.points || 0}`,
    sectionIdent,
    textInitY +
      lineJumpStep +
      lineJumpHalfStep * 6 +
      lineJumpStep * 4 +
      lineJumpHalfStep
  );

  doc.text(
    `Postura: ${sect1.subsections[1]?.points || 0}`,
    sectionIdent,
    textInitY +
      lineJumpStep +
      lineJumpHalfStep * 6 +
      lineJumpStep * 4 +
      lineJumpHalfStep * 2
  );

  doc.text(
    `Movimentos: ${sect1.subsections[2]?.points || 0}`,
    sectionIdent,
    textInitY +
      lineJumpStep +
      lineJumpHalfStep * 6 +
      lineJumpStep * 4 +
      lineJumpHalfStep * 3
  );

  doc.text(
    `Tônus: ${sect1.subsections[3]?.points || 0}`,
    sectionIdent,
    textInitY +
      lineJumpStep +
      lineJumpHalfStep * 6 +
      lineJumpStep * 4 +
      lineJumpHalfStep * 4
  );

  doc.text(
    `Reflexos e Reações: ${sect1.subsections[4]?.points || 0}`,
    sectionIdent,
    textInitY +
      lineJumpStep +
      lineJumpHalfStep * 6 +
      lineJumpStep * 4 +
      lineJumpHalfStep * 5
  );

  sectionIdent = ident + identStep * 2;

  doc.text(
    `NÚMERO DE ASSIMETRIAS: ${hineData?.assimetrias || 0}`,
    sectionIdent,
    textInitY +
      lineJumpStep +
      lineJumpHalfStep * 6 +
      lineJumpStep * 4 +
      lineJumpHalfStep * 5 +
      lineJumpStep
  );

  sectionIdent = ident + identStep;

  doc.text(
    `ANÁLISE:`,
    sectionIdent,
    textInitY +
      lineJumpStep +
      lineJumpHalfStep * 6 +
      lineJumpStep * 4 +
      lineJumpHalfStep * 5 +
      lineJumpStep * 2
  );

  sectionIdent = ident + identStep * 2;

  if (moment().diff(patientData.birthDate, "months") <= 3) {
    doc.text(
      "O paciente possui idade corrigida abaixo dos níveis de evidência para avaliação",
      sectionIdent,
      textInitY +
        lineJumpStep +
        lineJumpHalfStep * 6 +
        lineJumpStep * 4 +
        lineJumpHalfStep * 5 +
        lineJumpStep * 3
    );

    doc.text(
      "do HINE. Orienta-se reavaliação a partir dos 3 meses de idade corrigida, além da",
      sectionIdent,
      textInitY +
        lineJumpStep +
        lineJumpHalfStep * 6 +
        lineJumpStep * 4 +
        lineJumpHalfStep * 5 +
        lineJumpStep * 3 +
        lineJumpHalfStep
    );

    doc.text(
      "manutenção no acompanhamento do desenvolvimento infantil.",
      sectionIdent,
      textInitY +
        lineJumpStep +
        lineJumpHalfStep * 6 +
        lineJumpStep * 4 +
        lineJumpHalfStep * 5 +
        lineJumpStep * 3 +
        lineJumpHalfStep * 2
    );
  } else {
    doc.text(
      `(${otima ? "x" : " "}) PONTUAÇÃO ÓTIMA`,
      sectionIdent,
      textInitY +
        lineJumpStep +
        lineJumpHalfStep * 6 +
        lineJumpStep * 4 +
        lineJumpHalfStep * 5 +
        lineJumpStep * 3
    );

    doc.text(
      `(${otima ? " " : "x"}) PONTUAÇÃO SUB-ÓTIMA`,
      sectionIdent,
      textInitY +
        lineJumpStep +
        lineJumpHalfStep * 6 +
        lineJumpStep * 4 +
        lineJumpHalfStep * 5 +
        lineJumpStep * 3 +
        lineJumpHalfStep
    );

    doc.text(
      `O(A) paciente apresenta pontuação global de ${pontuacao}, correspondendo a escore ${pontuacao}, o que caracteriza`,
      sectionIdent,
      textInitY +
        lineJumpStep +
        lineJumpHalfStep * 6 +
        lineJumpStep * 4 +
        lineJumpHalfStep * 5 +
        lineJumpStep * 3 +
        lineJumpHalfStep +
        lineJumpStep
    );

    sectionIdent -= identStep;

    doc.text(
      otima ? textoOtimo : textoSubOtimo,
      sectionIdent,
      textInitY +
        lineJumpStep +
        lineJumpHalfStep * 6 +
        lineJumpStep * 4 +
        lineJumpHalfStep * 5 +
        lineJumpStep * 3 +
        lineJumpHalfStep +
        lineJumpStep +
        lineJumpHalfStep
    );
  }

  doc.save(`${name}.pdf`);
}

import PageLayout from "examples/LayoutContainers/PageLayout";

import Slide from "@mui/material/Slide";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import MDBox from "components/MDBox";
import SectionOneSlide from "./slide";

import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Keys } from "../../reducers/data";

import { useSelector, useDispatch } from "react-redux";
import { setCurrentSection, updateData } from "../../reducers";
import { hasUnselectedTopics } from "reducers/data_last";

function HineForm(props) {
  const [currentForm, setCurrentForm] = useState(Keys.Nerve);
  const [subsections, setSubSections] = useState([]);
  const containerRef = React.useRef(null);
  const hineData = useSelector((state) => state.hine.data);
  const currentHine = useSelector((state) => state.hine.currentHine);
  const currentSection = useSelector((state) => state.hine.currentSection);
  const currentSubsection = useSelector(
    (state) => state.hine.currentSubsection
  );
  const [sectionData, setSectionData] = useState(null);
  const navigate = useNavigate();
  const disp = useDispatch();
  const [form, setForm] = useState(null);
  const currentPatientId = useSelector((state) => state.hine.currentPatient);

  useEffect(() => {
    if (!currentPatientId) {
      navigate("/hine-menu");
    }

    setForm(hineData[currentPatientId]);
  }, [currentPatientId, hineData, navigate]);

  useEffect(() => {
    if (!currentHine || !currentSection || !currentSubsection) {
      navigate("/hine-menu");
    }
    if (form) {
      const section = form[currentHine].find(
        (sec) => sec.key === currentSection
      );
      setSectionData(section);

      if (currentSection !== "section1") {
        setSubSections([section]);
        setCurrentForm(currentSubsection);
      } else {
        setCurrentForm(currentSubsection);
        setSubSections(section.subsections);
      }
    }
  }, [form, currentHine, currentSection, currentSubsection, navigate]);

  const updateScore = (
    key,
    score = null,
    assimetry = null,
    selectedScoreKey = "selectedScore",
    multipleSelectedScore = null,
    actualScore = 0
  ) => {
    const payload = {
      hine: currentHine,
      patientId: currentPatientId,
      section: currentSection,
      subSection: currentSubsection,
      key,
      score,
      assimetry,
      selectedScoreKey,
      multipleSelectedScore,
      actualScore,
    };
    disp(updateData(payload));
  };

  return (
    <PageLayout>
      <MDBox fullWidth>
        <DefaultNavbar
          action={{
            type: "external",
            route: "https://google.com",
            label: "free download",
            color: "dark",
          }}
          light={false}
        />
      </MDBox>

      <MDBox
        sx={{ display: "flex", padding: "30px", alignItems: "stretch" }}
        fullWidth
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ fontSize: "12px" }}
        >
          {[
            <Link
              underline="hover"
              to={"/hine-menu"}
              onClick={(e) => {
                e.preventDefault();
                const ss = subsections.find(
                  (ss) => ss.key === currentSubsection
                );
                if (ss && hasUnselectedTopics(ss.topics)) {
                  alert("Existem topicos sem pontuacao");
                } else {
                  navigate("/hine-menu");
                }
              }}
            >
              Menu
            </Link>,
            <Link
              underline="hover"
              to={"/hine-sections-menu"}
              onClick={(e) => {
                e.preventDefault();
                const ss = subsections.find(
                  (ss) => ss.key === currentSubsection
                );
                if (ss && hasUnselectedTopics(ss.topics)) {
                  alert("Existem topicos sem pontuacao");
                } else {
                  disp(setCurrentSection(""));
                  navigate("/hine-sections-menu");
                }
              }}
            >
              {currentHine.toUpperCase()}
            </Link>,
            sectionData && (
              <Link underline="hover" to={"/hine-sections-menu"}>
                {sectionData.title.toUpperCase()}
              </Link>
            ),
          ]}
        </Breadcrumbs>
      </MDBox>

      <Typography variant="h4" sx={{ margin: "10px" }}>
        Pontuação da seção: {sectionData?.points || 0}
      </Typography>

      <MDBox
        sx={{ position: "relative", marginLeft: "10px", marginRight: "10px" }}
        md={6}
        lg={2}
        alignItems="center"
        display="flex"
        justifyContent="center"
      >
        {subsections.map((data) => {
          return (
            <Slide
              direction="left"
              in={currentForm === data.key}
              container={containerRef.current}
              sx={{ position: "absolute", top: 0 }}
              md={10}
              lg={5}
              key={data.key}
            >
              <MDBox>
                {sectionData.title.includes("1") && (
                  <Typography variant="h4" sx={{ margin: "10px" }}>
                    Pontuação da subseção: {data?.points || 0}
                  </Typography>
                )}
                <SectionOneSlide
                  updateScore={updateScore}
                  title={data.title}
                  topics={data.topics}
                  isSection2={data.key === "section2"}
                />
              </MDBox>
            </Slide>
          );
        })}
      </MDBox>
    </PageLayout>
  );
}

export default HineForm;

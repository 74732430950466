import { useState } from "react";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/authentication/components/BasicLayout";
import hine_image from "../../main-menu/hine.png";
import bgImage from "../../main-menu/bg.jpeg";

import { setAuth, clear } from "../../../reducers/auth";

import axios from "axios";
import env from "react-dotenv";
import { useNavigate } from "react-router-dom";
import { Box, Fade } from "@mui/material";

//import bgImage from "assets/images/bg-sign-up-cover.jpeg";

function Basic() {
  const [data, setData] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const customErrorMessage = "Erro fazendo login, tente mais tarde";
  const [showError, setShowError] = useState(false);
  const disp = useDispatch();

  const login = () => {
    const url = `${env.API_URL}/auth/login`;
    axios
      .post(url, data)
      .then((loginResp) => {
        console.log("login", loginResp);
        if (loginResp.status === 200) {
          const authData = {
            email: loginResp.data.body.email,
            logedIn: true,
            token: loginResp.data.token,
            userId: loginResp.data.body._id,
            userName: loginResp.data.body.name,
          };

          disp(setAuth(authData));
          navigate("/mainmenu");
        } else {
          disp(clear());
          setShowError(true);
        }
      })
      .catch((err) => {
        disp(clear());
        setShowError(true);
        console.log(err);
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card style={{paddingBottom: 10}}> 
        <img
          style={{ margin: "10px", marginBottom: "50px" }}
          src={hine_image}
          alt="logo"
        />

        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
              Fazer login
          </MDTypography>
          {/* <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          ></Grid> */}
        </MDBox>
        <MDBox pt={4} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                value={data.email}
                label="Email"
                fullWidth
                onChange={(evt) => {
                  setData({ ...data, email: evt.target.value });
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                value={data.password}
                label="Senha"
                fullWidth
                onChange={(evt) => {
                  setData({ ...data, password: evt.target.value });
                }}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={login}
              >
                entrar
              </MDButton>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                Nao tem conta?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Criar conta
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography variant="button" color="text">
                Esqueceu sua senha?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/reset-password/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Resetar senha
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        {showError && (
          <Fade in={showError}>
            <MDBox variant="gradient" bgColor="info" mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" mx={2} fontWeight="medium" color="white">
                {customErrorMessage}
              </MDTypography>
            </MDBox>
          </Fade>
        )}
      </Card>
    </BasicLayout>
  );
}

export default Basic;

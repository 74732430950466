import MDButton from "components/MDButton";
import PageLayout from "examples/LayoutContainers/PageLayout";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentSection, setCurrentSubSection } from "../../reducers";
import { hasUnselectedTopics } from "reducers/data_last";

function HineSectionsPage() {
  const currentPatientId = useSelector((state) => state.hine.currentPatient);
  const currentHine = useSelector((state) => state.hine.currentHine);
  const hineData = useSelector((state) => state.hine.data);
  const currentSection = useSelector((state) => state.hine.currentSection);
  const currentSubsection = useSelector(
    (state) => state.hine.currentSubsection
  );
  const [titles, setTitles] = useState([]);
  const disp = useDispatch();
  const navigate = useNavigate();
  const [sectionData, setSectionData] = useState(null);
  const [form, setForm] = useState(null);

  useEffect(() => {
    if (!currentPatientId) {
      navigate("/hine-menu");
    }

    setForm(hineData[currentPatientId]);
  }, [currentHine, currentSection, currentPatientId, navigate, hineData]);

  useEffect(() => {
    if (!currentHine) {
      navigate("/hine-menu");
    }
    if (form) {
      setTitles(form[currentHine]);

      if (currentSection) {
        const section = form[currentHine].find(
          (sec) => sec.key === currentSection
        );

        setSectionData(section);
        if (currentSection === "section1") {
          setTitles(
            form[currentHine].find((k) => k.key === "section1").subsections
          );
        }
      } else {
        setSectionData(null);
      }
    }
  }, [form, currentHine, currentSection, navigate]);

  return (
    <PageLayout>
      <DefaultNavbar
        action={{
          type: "external",
          route: "https://google.com",
          label: "free download",
          color: "dark",
        }}
        light={false}
      />
      <MDBox
        sx={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
        }}
        fullWidth
      >
        <MDBox sx={{ display: "flex", padding: "10px" }} fullWidth>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
            maxItems={2}
            sx={{ fontSize: "12px" }}
          >
            {[
              <Link underline="hover" to={"/hine-menu"}>
                Menu
              </Link>,
              <Link
                underline="hover"
                to={"/hine-sections-menu"}
                onClick={() => {
                  disp(setCurrentSection(""));
                }}
              >
                {currentHine.toUpperCase()}
              </Link>,
              sectionData && (
                <Link underline="hover" to={"/"}>
                  {sectionData.title.toUpperCase()}
                </Link>
              ),
            ]}
          </Breadcrumbs>
        </MDBox>
        {titles.map((entry) => (
          <MDButton
            sx={{ margin: "5px" }}
            variant="gradient"
            color="info"
            fullWidth
            lg={6}
            key={entry.key}
            onClick={() => {
              if (entry.key === "section1") {
                disp(setCurrentSection(entry.key));
              }
              if (entry.key === "section2" || entry.key === "section3") {
                disp(setCurrentSection(entry.key));
                disp(setCurrentSubSection(entry.key));
                navigate("/hine-form");
              }

              if (currentSection === "section1") {
                disp(setCurrentSubSection(entry.key));
                navigate("/hine-form");
              }
            }}
          >
            {entry.title}
          </MDButton>
        ))}
      </MDBox>
      {currentSection === "section1" && (
        <>
          <Typography variant="h4" sx={{ margin: "30px" }}>
            Pontuação da seção: {sectionData?.points || 0}
          </Typography>
          <MDButton
            sx={{ margin: "10px" }}
            variant="gradient"
            color="info"
            onClick={() => {
              disp(setCurrentSection(""));
              navigate("/hine-sections-menu");
            }}
            fullWidth
          >
            Salvar
          </MDButton>
          <MDButton
            sx={{ margin: "10px" }}
            variant="gradient"
            color="info"
            onClick={() => {
              disp(setCurrentSection(""));
              navigate("/hine-sections-menu");
            }}
            fullWidth
          >
            Salvar e ir para Relatórios
          </MDButton>
        </>
      )}
    </PageLayout>
  );
}

export default HineSectionsPage;

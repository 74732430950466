export const Keys = {
  Nerve: 1,
  Postura: 2,
  Movements: 3,
  Tonus: 4,
  Reactions: 5,
  Cabeca: 6,
  Sentar: 7,
  Prensao: 8,
  Habilidade: 9,
  Rolar: 10,
  Engatinhar: 11,
  EmPe: 12,
  Marcha: 13,
  Conciente: 14,
  Emocional: 15,
  Orientacao: 16,
};

export const section1 = {
  title: "Exame neurologico",
  key: "section1",
  subsections: [
    {
      title: "Avaliacao da funcao dos nervos cranianos",
      key: Keys.Nerve,
      topics: [
        {
          title: "Aparencia facial",
          description:
            "(em repouso e quando está chorando ou sendo estimulado)",
          key: Keys.Nerve + 100,
          scores: {
            3: {
              title: "Sorri ou reage a estimulos fechando os olhos",
              img: "",
            },
            2: { title: "", img: "" },
            1: {
              title: "Feha os olhos mas nao aperta, pobre expressao facial",
              img: "",
            },
            0: { title: "Sem expressoes, nao reage a estimulos", img: "" },
          },
          selectedScore: -1,
          ass: false,
          assComment: "",
        },
        {
          title: "Movimento dos olhos",
          description: "daksjhdlkajshd",
          key: Keys.Nerve + 101,
          scores: {
            3: {
              title: "Movimentos normais e coordenados (ambos lados)",
              img: "",
            },
            2: { title: "", img: "" },
            1: {
              title: "Intermitente, Desvio dos olhos ou movimentos anormais",
              img: "",
            },
            0: {
              title: "Continuo, Desvio dos olhos ou movimentos anormais.",
              img: "",
            },
          },
          selectedScore: -1,
          ass: false,
          assComment: "",
        },
        {
          title: "Resposta visual",
          description: "",
          key: Keys.Nerve + 102,
          scores: {
            3: { title: "Acompanha o alvo em um arco completo", img: "" },
            2: { title: "", img: "" },
            1: {
              title: "Acompanha o alvo em um arco incompleto ou assimétrico",
              img: "",
            },
            0: { title: "Nao acompanha o alvo", img: "" },
          },
          ass: false,
          assComment: "",
          selectedScore: -1,
        },
        {
          title: "Sugar/Deglutir",
          description: "sdsjdksjd",
          key: Keys.Nerve + 103,
          scores: {
            3: { title: "Boa succao e degluticao", img: "" },
            2: { title: "", img: "" },
            1: { title: "Pobre succao e/ou degluticao", img: "" },
            0: { title: "Sem reflexo de succao, sem degluticao", img: "" },
          },
          ass: false,
          assComment: "",

          selectedScore: -1,
        },
      ],
    },
    {
      title: "Avaliacao da Postura",
      key: Keys.Postura,
      topics: [
        {
          title: "Cabeca",
          key: Keys.Postura + 100,
          scores: {
            3: { title: "Reta na linha media", img: "postura_cabeca_3" },
            2: { title: "", img: "" },
            1: {
              title: "Levemente para o lado ou para tras ou para frente",
              img: "postura_cabeca_1",
            },
            0: {
              title: "Acentuadamente para o lado, para tras ou para frente",
              img: "postura_cabeca_0",
            },
          },

          selectedScore: -1,
        },
        {
          title: "Bracos",
          key: Keys.Postura + 101,
          scores: {
            3: {
              title: "Em posicao neutra, retos no centro ou levemente fletidos",
              img: "",
            },
            2: { title: "", img: "" },
            1: {
              title:
                "Rotacao interna ou rotacao externa (Leve), Postura distonica (Intermitente)",
              img: "",
            },
            0: {
              title:
                "Rotacao interna ou rotacao externa (Acentuado), Postura distonica, postura hemiplegica",
              img: "",
            },
          },

          selectedScore: -1,
        },
        {
          title: "Maos",
          key: Keys.Postura + 102,
          scores: {
            3: { title: "Maos abertas", img: "" },
            2: { title: "", img: "" },
            1: {
              title: "Polegar aduzido ou mao cerrada (Intermitente)",
              img: "",
            },
            0: {
              title: "Polegar aduzido ou mar cerrada (Persistente)",
              img: "",
            },
          },

          selectedScore: -1,
        },
        {
          title: "Pernas Sentado",
          key: Keys.Postura + 103,
          scores: {
            3: {
              title:
                "Capaz de sentar com o tronco reto e pernas retas ou levemente fletidas (pode permanecer sentado com as pernas estendidas).",
              img: "",
            },
            2: { title: "", img: "" },
            1: {
              title:
                "Senta com o tronco reto, mas com os joelhos fletidos a 15 - 20 graus.",
              img: "",
            },
            0: {
              title:
                "Incapaz de sentar reto, a menos que os joelhos estejam acentuadamente fletidos (nao permanece sentado com as pernas estendidas)",
              img: "",
            },
          },

          selectedScore: -1,
        },
        {
          title: "Pernas Supino em pe",
          key: Keys.Postura + 104,
          scores: {
            3: {
              title: "Pernas em posicao neutra, retas ou levemente fletidas",
              img: "postura_supino_3",
            },
            2: { title: "Rotacao interna ou rotacao externa (Leve)", img: "" },
            1: {
              title: "Rotacao interna ou rotacao externa de quadris",
              img: "postura_supino_1",
            },
            0: {
              title:
                "Rotacao interna ou rotacao externa ou extensao ou flexao fixa ou contraturas nos quadris e joelhos (Acentuado)",
              img: "postura_supino_0",
            },
          },

          selectedScore: -1,
        },
        {
          title: "Pes (em supino em pe)",
          key: Keys.Postura + 105,
          scores: {
            3: {
              title:
                "Centralizados na posicao neutra, dedos retos entre semi flexao e extensao (em posicao neutra)",
              img: "",
            },
            2: { title: "", img: "" },
            1: {
              title:
                "Rotacao interna e rotacao externa (Leve), Tendencia de ficar em pe na ponta dos pes ou com dedos fletidos ou estendidos (Intermitente)",
              img: "",
            },
            0: {
              title:
                "Rotacao interna ou rotacao externa do tornozelo (Acentuado), Tendencia de ficar em pe na ponta dos pes ou com dedos fletidos ou estendidos (Persistente)",
              img: "",
            },
          },

          selectedScore: -1,
        },
      ],
    },
    {
      title: "Avaliacao dos Movimentos",
      key: Keys.Movements,
      topics: [
        {
          title: "Quantidade, crianca sentada em supino",
          key: Keys.Movements + 100 + 1,
          scores: {
            3: { title: "Normal", img: "" },
            2: { title: "", img: "" },
            1: { title: "Excessivo ou lento", img: "" },
            0: { title: "Minimo ou nenhum", img: "" },
          },

          selectedScore: -1,
        },
        {
          title: "Qualidade, atividade motora espontanea",

          key: Keys.Movements + 100 + 2,
          scores: {
            3: { title: "Livre, alternado e suave", img: "" },
            2: { title: "", img: "" },
            1: { title: "Brusco, leve tremor", img: "" },
            0: {
              title:
                "Rigido e sincronico, espamos extensores, Aletoide, Ataxico, Muito tremulo, Espasmo mioclonico, Movimento distonico",
              img: "",
            },
          },

          selectedScore: -1,
        },
      ],
    },
    {
      title: "Avaliacao Tonus",
      key: Keys.Tonus,
      topics: [
        {
          title: "Sinal de cachecol",

          key: Keys.Tonus + 100 + 1,
          hasRl: true,
          scores: {
            3: {
              title: "Amplitude (Direita esquerda)",
              img: "tonus_cachecol_3",
              rl: 1,
            },
            2: { title: "", img: "", rl: 1 },
            1: { title: "Direta ou esquerda", img: "tonus_cachecol_1", rl: 1 },
            0: {
              title: "Direita esquerda ou Direta esquerda",
              img: "tonus_cachecol_0",
              rl: 1,
            },
          },

          selectedScore: -1,
        },
        {
          title: "Elevacao passiva do ombro",

          key: Keys.Tonus + 100 + 2,
          scores: {
            3: { title: "Resistencia superavel", img: "tonus_ombro_3" },
            2: { title: "Resistencia dificil de superar", img: "" },
            1: { title: "Sem resistencia", img: "tonus_ombro_1" },
            0: { title: "Resistencia nao superavel", img: "tonus_ombro_0" },
          },

          selectedScore: -1,
        },
        {
          title: "Pronacao/supinacao",

          key: Keys.Tonus + 100 + 3,
          scores: {
            3: {
              title: "Pronacao completa e supinacao, sem resistencia",
              img: "",
            },
            2: { title: "", img: "" },
            1: {
              title:
                "Resistencia total e pronacao completa / supinacao superavel",
              img: "",
            },
            0: {
              title:
                "Nao eh possivel pronacao completa e supinacao, resistencia acentuada.",
              img: "",
            },
          },

          selectedScore: -1,
        },
        {
          title: "Adutores de quadril",

          key: Keys.Tonus + 100 + 4,
          scores: {
            3: { title: "Amplitude 150-80 graus", img: "tonus_quadril_3" },
            2: { title: "150-160 graus", img: "tonus_quadril_2" },
            1: { title: ">170 graus", img: "tonus_quadril_1" },
            0: { title: "<80 graus", img: "tonus_quadril_0" },
          },

          selectedScore: -1,
        },
        {
          title: "Angulo popliteo",

          key: Keys.Tonus + 100 + 5,
          scores: {
            3: { title: "Amplitude 150-100 graus", img: "tonus_popliteo_3" },
            2: { title: "150-160 graus", img: "tonus_popliteo_2" },
            1: { title: "~90 ou >170 graus", img: "tonus_popliteo_1" },
            0: { title: "<80 graus", img: "tonus_popliteo_0" },
          },

          selectedScore: -1,
        },
        {
          title: "Dorsiflexao de tornozelo",

          key: Keys.Tonus + 100 + 6,
          scores: {
            3: { title: "Amplitude 30 - 85 graus", img: "tonus_tornozelo_3" },
            2: { title: "20 - 30 graus", img: "tonus_tornozelo_2" },
            1: { title: "<20 ou 90 graus", img: "tonus_tornozelo_1" },
            0: { title: "> 90 graus", img: "tonus_tornozelo_0" },
          },

          selectedScore: -1,
        },
        {
          title: "Puxado para sentar",

          key: Keys.Tonus + 100 + 7,
          scores: {
            3: { title: "", img: "tonus_sentar_3" },
            2: { title: "", img: "" },
            1: { title: "", img: "tonus_sentar_2" },
            0: { title: "", img: "tonus_sentar_1" },
          },

          selectedScore: -1,
        },
        {
          title: "Suspensao ventral",

          key: Keys.Tonus + 100 + 8,
          scores: {
            3: { title: "", img: "tonus_ventral_3" },
            2: { title: "", img: "" },
            1: { title: "", img: "tonus_ventral_1" },
            0: { title: "", img: "tonus_ventral_0" },
          },

          selectedScore: -1,
        },
      ],
      ass: false,
      assComment: "",
    },
    {
      title: "Reflexos e reacoes",
      key: Keys.Reactions,
      topics: [
        {
          key: Keys.Reactions + 100 + 1,
          title: "Protecao do braco",
          scores: {
            3: { title: "Braco e mao estendidos", img: "reacao_braco_3" },
            2: { title: "", img: "" },
            1: { title: "Braco semiflexionado", img: "reacao_braco_1" },
            0: {
              title: "Braco completamente flexionado",
              img: "reacao_braco_0",
            },
          },

          selectedScore: -1,
        },
        {
          key: Keys.Reactions + 100 + 2,
          title: "Suspencao vertical",
          scores: {
            3: { title: "Chuta", img: "reacao_vertical_3" },
            2: { title: "", img: "" },
            1: { title: "Chuta mais", img: "reacao_vertical_2" },
            0: { title: "Imagem", img: "reacao_vertical_1" },
          },

          selectedScore: -1,
        },
        {
          title: "Inclinacao lateral",

          key: Keys.Reactions + 100 + 3,
          scores: {
            3: { title: "", img: "reacao_lateral_3" },
            2: { title: "", img: "reacao_lateral_2" },
            1: { title: "", img: "reacao_lateral_1" },
            0: { title: "", img: "reacao_lateral_0" },
          },

          selectedScore: -1,
        },
        {
          title: "Paraquedas anterior",

          key: Keys.Reactions + 100 + 4,
          scores: {
            3: { title: "", img: "reacao_anterior_3" },
            2: { title: "", img: "" },
            1: { title: "", img: "reacao_anterior_1" },
            0: { title: "", img: "" },
          },

          selectedScore: -1,
        },
        {
          title: "Reflexos tendinosos",

          key: Keys.Reactions + 100 + 5,
          scores: {
            3: {
              title:
                "Reflexos normais. Facilmente excitavel, biceps, joelho, tornozelo. ",
              img: "",
            },
            2: {
              title:
                "Reflexos hipoativos. Levemente ativo, biceps, joelho, tornozelo",
              img: "",
            },
            1: {
              title:
                "Reflexos hiperativos. Levemente ativo, biceps, joelho, tornozelo",
              img: "",
            },
            0: {
              title: "Clonus ou ausente",
              img: "",
            },
          },

          selectedScore: -1,
        },
      ],
      ass: false,
      assComment: "",
    },
  ],
};

export const section2 = {
  title: "Marcos motorores",
  key: "section2",
  topics: [
    {
      title: "Controle da cabeca",
      key: Keys.Cabeca,
      scores: {
        5: {
          title: "Incapaz de manter a cabeca levantada",
          img: "",
        },
        4: {
          title: "Oscilacoes, Normal ate 4 meses",
          img: "",
        },
        3: {
          title:
            "Mantem a cabeca ereta todo o tempo, Normal a partir de 5 meses.",
          img: "",
        },
      },
      ass: false,
      assComment: "",
      selectedScore: -1,
    },
    {
      title: "Sentar",
      key: Keys.Sentar,
      scores: {
        5: {
          title: "Nao pode sentar",
          img: "",
        },
        4: {
          title: "Com suporte nos quadris, Normal aos 4 meses",
          img: "sec02_motores_sentar01",
        },
        3: {
          title: "Escorado, Normal aos 6 meses",
          img: "sec02_motores_sentar02",
        },
        2: {
          title: "Senta-se estavel, Normal aos 7-8 meses",
          img: "sec02_motores_sentar03",
        },
        1: {
          title: "Pivoteia (gira) Normal aos 9 meses",
          img: "sec02_motores_sentar04",
        },
      },
      selectedScore: -1,
      ass: false,
      assComment: "",
    },
    {
      title: "Preensao voluntaria, observe o lado",
      key: Keys.Prensao,
      scores: {
        5: {
          title: "Sem preensao",
          img: "",
        },
        4: {
          title: "Usa toda mao",
          img: "",
        },
        3: {
          title: "Dedo indicador e polegar, mas preensao imatura",
          img: "",
        },
        2: {
          title: "Preensao em pinca",
          img: "",
        },
      },
      selectedScore: -1,
      ass: false,
      assComment: "",
    },
    {
      title: "Habilidade de chutar em supino",
      key: Keys.Habilidade,
      scores: {
        5: {
          title: "Nao chuta",
          img: "",
        },
        4: {
          title: "Chuta horizontalmente, mas nao eleva as pernas",
          img: "",
        },
        3: {
          title: "Para cima (verticalmente), Normal aos 3 meses",
          img: "sec02_motores_chutar01",
        },
        2: {
          title: "Toca a perna, Normal aos 4-5 meses",
          img: "sec02_motores_chutar02",
        },
        1: {
          title: "Toca nos dedos dos pes, Normal aos 5-6 meses",
          img: "sec02_motores_chutar03",
        },
      },
      selectedScore: -1,
      ass: false,
      assComment: "",
    },
    {
      title: "Rolar - Observe para qual lado",
      key: Keys.Rolar,
      scores: {
        5: {
          title: "Nao rola",
          img: "",
        },
        4: {
          title: "Rola para o lado (normal aos 4 meses)",
          img: "",
        },
        3: {
          title: "Rola de prono para supino, normal aos 6 meses",
          img: "",
        },
        2: {
          title: "Rola de supino para prono, normal aos 6 meses",
          img: "",
        },
      },
      ass: false,
      assComment: "",
    },
    {
      title: "Engatinhar - observa se arrasta sentado",
      key: Keys.Engatinhar,
      scores: {
        5: {
          title: "Nao levanta a cabeca",
          img: "",
        },
        4: {
          title: "Com cotovelo (normal aos 3 meses)",
          img: "sec02_motores_engatinhar01",
        },
        3: {
          title: "Com os bracos e maos super estandidos (normal aos 4 meses)",
          img: "sec02_motores_engatinhar02",
        },
        2: {
          title: "Engatinha com abdomen (normal aos 8 meses)",
          img: "sec02_motores_engatinhar04",
        },
        1: {
          title: "Engatinha sobre maos e joelhos (normal aos 10 meses)",
          img: "",
        },
      },
      ass: false,
      assComment: "",
    },
    {
      title: "Em pe",
      key: Keys.EmPe,
      scores: {
        5: {
          title: "Nao sustenta o peso",
          img: "",
        },
        4: {
          title: "Sustenta o peso (normal aos 4 meses)",
          img: "",
        },
        3: {
          title: "Em pe com apoio (normal aos 7 meses)",
          img: "",
        },
        2: {
          title: "Em pe sem ajuda (normal aos 12 meses)",
          img: "",
        },
      },
      ass: false,
      assComment: "",
      selectedScore: -1,
    },
    {
      title: "Marcha",
      key: Keys.Marcha,
      scores: {
        5: {
          title: "",
          img: "",
        },
        4: {
          title: "",
          img: "",
        },
        3: {
          title: "Saltando (normal aos 6 meses)",
          img: "",
        },
        2: {
          title: "Marcha lateral (se segurando) (normal aos 12 meses)",
          img: "",
        },
        1: {
          title: "Marcha independente (normal aos 15 meses)",
          img: "",
        },
      },
      ass: false,
      assComment: "",
      selectedScore: -1,
    },
  ],
};

export const section3 = {
  title: "Comportamento",

  key: "section3",
  topics: [
    {
      title: "Estado consciente",
      key: Keys.Conciente,
      scores: {
        1: {
          title: "Irritado",
          img: "",
        },
        2: {
          title: "Sonolento",
          img: "",
        },
        3: {
          title: "Dormido, mas acorda facilmente",
          img: "",
        },
        4: {
          title: "Acordado mas sem interesse",
          img: "",
        },
        5: {
          title: "Perde interesse",
          img: "",
        },
        6: {
          title: "Mantem interesse",
          img: "",
        },
      },
      ass: false,
      assComment: "",
      selectedScore: -1,
    },
    {
      title: "Estado emocional",
      key: Keys.Emocional,
      scores: {
        1: {
          title: "Irritado, nao consolavel",
          img: "",
        },
        2: {
          title: "Irritado, cuidador pode consolar",
          img: "",
        },
        3: {
          title: "Irritavel quando se aproxima",
          img: "",
        },
        4: {
          title: "Nem feliz ou infeliz",
          img: "",
        },
        5: {
          title: "Feliz e sonrridente",
          img: "",
        },
      },
      ass: false,
      assComment: "",
      selectedScore: -1,
    },
    {
      title: "Orientacao social",
      key: Keys.Orientacao,
      scores: {
        1: {
          title: "Evitando, retirando",
          img: "",
        },
        2: {
          title: "Hesitante",
          img: "",
        },
        3: {
          title: "Aceita aproximacao",
          img: "",
        },
        4: {
          title: "Amigavel",
          img: "",
        },
      },
      ass: false,
      assComment: "",
      selectedScore: -1,
    },
  ],
};

export const HineFormTemplate = {
  patient: {},
  evaluator: {},
  hine: [section1, section2, section3],
  hine2: [section2, section3],
  id: 0,
  date: "",
  score: 0,
  currentHine: "",
  currentSection: "",
  currentSubsection: "",
};

export const Keys = {
  Nerve: 1,
  Postura: 2,
  Movements: 3,
  Tonus: 4,
  Reactions: 5,
  Cabeca: 6,
  Sentar: 7,
  Prensao: 8,
  Habilidade: 9,
  Rolar: 10,
  Engatinhar: 11,
  EmPe: 12,
  Marcha: 13,
  Conciente: 14,
  Emocional: 15,
  Orientacao: 16,
};

export const hasUnselectedTopics = (topics) => {
  console.log(topics);
  const ans = Boolean(topics.find((t) => {
    if ('selectedScoreDireita' in t && 'selectedScoreIzquerda' in t) {
      return t.selectedScoreDireita === -1 || t.selectedScoreIzquerda === -1;
    } else {
      return t.selectedScore === -1;
    }
  }));
  //return Boolean(topics.find((t) => t.selectedScore === -1));
  return ans;
};

export const section1 = {
  title: "SEÇÃO 1: EXAME NEUROLÓGICO",
  key: "section1",
  subsections: [
    {
      title: "AVALIAÇÃO DA FUNÇÃO DOS NERVOS CRANIANOS",
      key: Keys.Nerve,
      topics: [
        {
          title: "Aparência facial",
          description: "Em repouso e quando está chorando ou sendo estimulado",
          key: Keys.Nerve + 100,
          scores: {
            3: {
              title:
                "Sorri ou reage a estímulos fechando os olhos e fazendo caretas",
              img: "",
              point: 3,
            },
            2: { title: "", img: "", point: 2 },
            1: {
              title: "Fecha os olhos, mas não aperta. Pobre expressão facial",
              img: "",
              point: 1,
            },
            0: {
              title: "Sem expressões, não reage a estímulos",
              img: "",
              point: 0,
            },
          },
          selectedScore: -1,
          ass: false,
          assComment: "",
          points: 0,
        },
        {
          title: "Movimento dos olhos",
          key: Keys.Nerve + 101,
          scores: {
            3: {
              title: "Movimentos normais e coordenados (ambos lados)",
              img: "",
              point: 3,
            },
            2: { title: "", img: "", point: 2 },
            1: {
              title:
                "<b>Intermitente</b>. Desvio dos olhos ou movimentos anormais",
              img: "",
              point: 1,
            },
            0: {
              title: "<b>Contínuo</b>. Desvio dos olhos ou movimentos anormais",
              img: "",
              point: 0,
            },
          },
          selectedScore: -1,
          ass: false,
          points: 0,
          assComment: "",
        },
        {
          title: "Resposta visual",
          description: "Testar a capacidade de acompanhar um alvo preto/branco",
          key: Keys.Nerve + 102,
          scores: {
            3: { title: "Acompanha o alvo em um arco completo", img: "" },
            2: { title: "", img: "" },
            1: {
              title: "Acompanha o alvo em um arco incompleto ou assimétrico",
              img: "",
            },
            0: { title: "Não acompanha o alvo", img: "" },
          },
          ass: false,
          assComment: "",
          selectedScore: -1,
        },
        {
          title: "Resposta auditiva",
          description: "Testar a resposta a um chocalho",
          key: Keys.Nerve + 110,
          scores: {
            3: { title: "Reage a estímulos de ambos os lados", img: "" },
            2: { title: "", img: "" },
            1: {
              title: "Reação duvidosa aos estímulos ou assimetria de resposta",
              img: "",
            },
            0: { title: "Sem resposta", img: "" },
          },
          ass: false,
          assComment: "",
          selectedScore: -1,
        },
        {
          title: "Sugar/Deglutir",
          description:
            "Observe a criança sugando o seio materno ou mamadeira. Se for mais velha, pergunte sobre alimentação, tosse associada e salivação excessiva",
          key: Keys.Nerve + 103,
          scores: {
            3: { title: "Boa sucção e deglutição", img: "" },
            2: { title: "", img: "" },
            1: { title: "Pobre sucção e/ou deglutição", img: "" },
            0: { title: "Sem reflexo de sucção, sem deglutição", img: "" },
          },
          ass: false,
          assComment: "",

          selectedScore: -1,
        },
      ],
    },
    {
      title: "AVALIAÇÃO DA POSTURA",
      description: "Observe quaisquer assimetrias",
      key: Keys.Postura,
      topics: [
        {
          title: "Cabeça",
          description: "Sentado",
          key: Keys.Postura + 100,
          scores: {
            3: { title: "Reta; na linha média", img: "postura_cabeca_3" },
            2: { title: "", img: "" },
            1: {
              title: "",
              multiple: [
                {
                  title: "Levemente para o lado",
                  img: "postura_cabeca_1_0",
                  id: "postura_cabeca_1_0",
                },
                {
                  title: "Para trás ou para frente",
                  img: "postura_cabeca_1_1",
                  id: "posture_cabeca_1_1",
                },
              ],
            },
            0: {
              title: "",
              multiple: [
                {
                  title: "Acentuadamente para o lado",
                  img: "postura_cabeca_0_0",
                  id: "postura_cabeca_0_0",
                },
                {
                  title: "Para trás ou para frente",
                  img: "postura_cabeca_0_1",
                  id: "posture_cabeca_0_1",
                },
              ],
            },
          },

          selectedScore: -1,
        },
        {
          title: "Tronco",
          description: "Sentado",
          key: Keys.Postura + 120,
          scores: {
            3: { title: "Reto", img: "postura_tronco_3" },
            2: { title: "", img: "" },
            1: {
              title: "Levemente curvado ou inclinado para o lado",
              img: "postura_tronco_1",
            },
            0: {
              title: "",
              multiple: [
                {
                  title: "Muito curvado",
                  img: "postura_tronco_0_1",
                  id: "tronco_0_0",
                },
                {
                  title: "Inclinado para trás",
                  img: "postura_tronco_0_2",
                  id: "tronco_0_1",
                },
                { title: "Inclinado para o lado", img: "postura_tronco_0_3" },
              ],
            },
          },

          selectedScore: "tronco_0_0",
        },
        {
          title: "Braços",
          description: "Em repouso",
          key: Keys.Postura + 130,
          hasRl: true,
          scores: {
            3: {
              title: "Em posição neutra, retos no centro ou levemente fletidos",
              img: "",
              rl: 1,
            },
            2: { title: "", img: "", rl: 1 },
            1: {
              title:
                "<b>Leve</b><br> Rotação interna ou rotação externa<br><b>Intermitente</b><br>Postura distônica",
              img: "",
              rl: 1,
            },
            0: {
              title:
                "<b>Acentuado</b><br>Rotação interna ou rotação externa<br>Postura distônica, Postura hemiplégica",
              img: "",
              rl: 1,
            },
          },

          selectedScore: -1,
        },
        {
          title: "Mãos",
          key: Keys.Postura + 140,
          hasRl: true,
          scores: {
            3: { title: "Mãos abertas", img: "", rl: 1 },
            2: { title: "", img: "", rl: 1 },
            1: {
              title: "<b>Intermitente</b>. Polegar aduzido ou mão cerrada",
              img: "",
              rl: 1,
            },
            0: {
              title: "<b>Persistente</b>. Polegar aduzido ou mão cerrada",
              img: "",
              rl: 1,
            },
          },

          selectedScore: -1,
        },
        {
          title: "Pernas (Sentado)",
          key: Keys.Postura + 150,
          hasRl: true,
          scores: {
            3: {
              title:
                "Capaz de sentar com o tronco reto e pernas retas ou levemente fletidas (pode permanecer sentado com as pernas estendidas)",
              img: "postura_supino_3",
              rl: 1,
            },
            2: { title: "", img: "", rl: 1 },
            1: {
              title:
                "Senta com o tronco reto, mas com os joelhos fletidos a 15 - 20 graus",
              img: "postura_supino_1",
              rl: 1,
            },
            0: {
              title:
                "Incapaz de sentar reto, a menos que os joelhos estejam acentuadamente fletidos (não permanece sentado com as pernas estendidas)",
              img: "postura_supino_0",
              rl: 1,
            },
          },

          selectedScore: -1,
        },
        {
          title: "Pernas (Supino e em pé)",
          key: Keys.Postura + 160,
          hasRl: true,
          scores: {
            3: {
              title: "Pernas em posição neutra, retas ou levemente fletidas",
              img: "",
              rl: 1,
            },
            2: {
              title: "<b>Leve</b>. Rotação interna ou rotação externa",
              img: "",
              rl: 1,
            },
            1: {
              title: "Rotação interna ou rotação externa de quadris",
              img: "",
              rl: 1,
            },
            0: {
              title:
                "<b>Acentuado</b>. Rotação interna ou rotação externa ou extensão ou flexão fixa ou contraturas nos quadris e joelhos",
              img: "",
              rl: 1,
            },
          },

          selectedScore: -1,
        },
        {
          title: "Pés",
          description: "Em supino e em pé",
          key: Keys.Postura + 170,
          hasRl: true,
          scores: {
            3: {
              title:
                "Centralizados na posição neutra.<br> Dedos retos entre semi flexão e extensão (em posição neutra)",
              img: "",
              rl: 1,
            },
            2: { title: "", img: "", rl: 1 },
            1: {
              title:
                "<b>Leve</b>.<br> Rotação interna e rotação externa.<br><b>Intermitente</b>. Tendência de ficar em pé na ponta dos pés ou com dedos fletidos ou estendidos",
              img: "",
              rl: 1,
            },
            0: {
              title:
                "<b>Acentuado</b>, Rotação interna ou rotação externa do tornozelo, <b>Persistente</b>, Tendência de ficar em pé na ponta dos pés ou com dedos fletidos ou estendidos",
              img: "",
              rl: 1,
            },
          },

          selectedScore: -1,
        },
      ],
    },
    {
      title: "AVALIAÇÃO DOS MOVIMENTOS",
      key: Keys.Movements,
      topics: [
        {
          title: "Quantidade",
          description: "Observe a criança deitada em supino",
          key: Keys.Movements + 100 + 1,
          scores: {
            3: { title: "Normal", img: "" },
            2: { title: "", img: "" },
            1: { title: "Excessivo ou lento", img: "" },
            0: { title: "Mínimo ou nenhum", img: "" },
          },

          selectedScore: -1,
        },
        {
          title: "Qualidade",
          description:
            "Observe a atividade motora espontânea voluntária do bebê  durante a avaliação",
          key: Keys.Movements + 100 + 2,
          scores: {
            3: { title: "Livre, alternado e suave", img: "" },
            2: { title: "", img: "" },
            1: { title: "Brusco, leve tremor", img: "" },
            0: {
              title:
                "- Rígido e sincrônico.<br> - Espamos extensores. <br> -Atetóide.<br> - Atáxico.<br> - Muito trêmulo. <br> - Espasmo mioclônico. <br>- Movimento distônico",
              img: "",
            },
          },

          selectedScore: -1,
        },
      ],
    },
    {
      title: "AVALIAÇÃO TÔNUS",
      key: Keys.Tonus,
      topics: [
        {
          title: "Sinal de cachecol",
          description:
            "Pegue a mão da criança e puxe o braço sobre o peito até encontrar resistência. Observe a posição do cotovelo em relação à linha média",
          key: Keys.Tonus + 100 + 1,
          hasRl: true,
          scores: {
            3: {
              title: "Amplitude (Direita esquerda)",
              img_i: "tonus_cachecol_3_izquerda",
              img_d: "tonus_cachecol_3_direita",
              rl: 1,
              splitEachRl: 1,
            },
            2: { title: "", img: "", rl: 1 },
            1: {
              title: "Direta ou esquerda",
              img: "",
              rl: 1,
              img_i: "tonus_cachecol_1",
              img_d: "tonus_cachecol_1",
            },
            0: {
              title: "Direita ou esquerda",
              img_i: "tonus_cachecol_0_izquerda",
              img_d: "tonus_cachecol_0_direita",
              rl: 1,
              splitEachRl: 1,
            },
          },

          selectedScoreIzquerda: -1,
          selectedScoreDireita: -1,
        },
        {
          title: "Elevação passiva do ombro",
          description:
            "Levante o braço ao lado da cabeça da criança. Observe a resistência no ombro e cotovelo",
          key: Keys.Tonus + 100 + 2,
          hasRl: true,
          scores: {
            3: {
              title: "Resistência superável",
              img: "tonus_ombro_3",
              rl: 1,
            },
            2: {
              title: "Resistência difícil de superar",
              img: "",
              rl: 1,
            },
            1: {
              title: "Sem resistência",
              img: "tonus_ombro_1",
              rl: 1,
            },
            0: {
              title: "Resistência não superável",
              img: "tonus_ombro_0",
              rl: 1,
            },
          },

          selectedScore: -1,
        },
        {
          title: "Pronação/supinação",
          description:
            "Estabilize o braço enquanto é feito a pronação e supinação no antebraço, observe a resistência",
          key: Keys.Tonus + 100 + 3,
          hasRl: true,
          scores: {
            3: {
              title: "Pronação completa e supinação, sem resistência",
              img: "",
              rl: 1,
            },
            2: { title: "", img: "", rl: 1 },
            1: {
              title:
                "Resistência total à pronação completa / supinação superável",
              img: "",
              rl: 1,
            },
            0: {
              title:
                "Não é possível pronação completa e supinação, resistência acentuada",
              img: "",
              rl: 1,
            },
          },

          selectedScore: -1,
        },
        {
          title: "Adutores de quadril",
          description:
            "Com as duas pernas da criança estendidas, faça abdução de ambos os membros, o mais distante possível. Observe o ângulo formado pelas pernas",
          key: Keys.Tonus + 100 + 4,
          hasRl: true,
          scores: {
            3: {
              title: "Amplitude: 150-80 graus",
              rl: 1,
              splitEachRl: 2,
              img_i: "tonus_quadril_3_0",
              img_d: "tonus_quadril_3_1",
              /* multipleSimpleTitle: [
                {
                  title: "",
                  img: "tonus_quadril_3_0",
                  id: "tonus_quadril_3_0",
                  rl: 1,
                },
                {
                  title: "",
                  img: "tonus_quadril_3_1",
                  id: "tonus_quadril_3_1",
                  rl: 1,
                },
              ], */
            },
            2: {
              title: "150-160 graus",
              img: "tonus_quadril_2",
              rl: 1,
            },
            1: {
              title: ">170 graus",
              img: "tonus_quadril_1",
              rl: 1,
            },
            0: {
              title: "<80 graus",
              img: "tonus_quadril_0",
              rl: 1,
            },
          },

          selectedScore: -1,
          multipleSelectedScore: -1,
        },
        {
          title: "Ângulo poplíteo",
          description:
            "Mantendo as nádegas da criança na cama, flexione os quadris sobre o abdômen, depois estenda os joelhos até encontrar resistência. Observe o ângulo entre a parte superior e inferior da perna",
          key: Keys.Tonus + 100 + 5,
          hasRl: true,
          scores: {
            3: {
              title: "Amplitude 150-100 graus (Direita esquerda)",
              rl: 1,
              splitEachRl: 1,
              img_i: "tonus_popliteo_3_0",
              img_d: "tonus_popliteo_3_1",
            },
            2: {
              title: "150-160 graus (Direita esquerda)",
              img: "tonus_popliteo_2",
              rl: 1,
            },
            1: {
              title: "~90 ou >170 graus (Direita esquerda)",
              img_i: "tonus_popliteo_1_0",
              img_d: "tonus_popliteo_1_1",
              rl: 1,
              splitEachRl: 1,
            },
            0: {
              title: "<80 graus (Direita esquerda)",
              img: "tonus_popliteo_0",
              rl: 1,
            },
          },

          selectedScore: -1,
          multipleSelectedScore: -1,
        },
        {
          title: "Dorsiflexão de tornozelo",
          description:
            "Com o joelho estendido, faça dorsiflexão do tornozelo. Observe o ângulo entre o pé e a perna",
          key: Keys.Tonus + 100 + 6,
          hasRl: true,
          scores: {
            3: {
              title: "Amplitude 30-85 graus (Direita esquerda)",
              img_i: "tonus_tornozelo_3_0",
              img_d: "tonus_tornozelo_3_1",
              rl: 1,
              splitEachRl: 1,
            },
            2: {
              title: "20-30 graus (Direita esquerda)",
              img: "tonus_tornozelo_2",
              rl: 1,
            },
            1: {
              title: "<20 ou 90 graus (Direita esquerda)",
              img_i: "tonus_tornozelo_1_0",
              img_d: "tonus_tornozelo_1_1",
              rl: 1,
              splitEachRl: 1,
            },
            0: {
              title: "> 90 graus (Direita esquerda)",
              img: "tonus_tornozelo_0",
              rl: 1,
            },
          },

          selectedScore: -1,
        },
        {
          title: "Puxado para sentar",
          description:
            "Puxe a criança para sentar pelos punhos (se necessário, apoie a cabeça)",
          key: Keys.Tonus + 100 + 7,
          scores: {
            3: {
              title: "",
              //img: "tonus_sentar_3",
              multiple: [
                {
                  title: "",
                  img: "tonus_sentar_3_0",
                  id: "tonus_sentar_3_0",
                },
                {
                  title: "",
                  img: "tonus_sentar_3_1",
                  id: "tonus_sentar_3_1",
                },
              ],
            },
            2: { title: "", img: "" },
            1: { title: "", img: "tonus_sentar_1" },
            0: { title: "", img: "tonus_sentar_0" },
          },

          selectedScore: -1,
        },
        {
          title: "Suspensão ventral",
          description:
            "Segure a criança horizontalmente, em suspensão ventral, com as mãos ao redor do tronco; observe a posição da coluna, membros e cabeça",
          key: Keys.Tonus + 100 + 8,
          scores: {
            3: {
              title: "",
              multiple: [
                {
                  title: "",
                  img: "tonus_ventral_3_0",
                  id: "tonus_ventral_3_0",
                },
                {
                  title: "",
                  img: "tonus_ventral_3_1",
                  id: "tonus_ventral_3_1",
                },
              ],
            },
            2: { title: "", img: "" },
            1: { title: "", img: "tonus_ventral_1" },
            0: { title: "", img: "tonus_ventral_0" },
          },

          selectedScore: -1,
        },
      ],
      ass: false,
      assComment: "",
    },
    {
      title: "REFLEXOS E REAÇÕES",
      key: Keys.Reactions,
      topics: [
        {
          key: Keys.Reactions + 100 + 1,
          hasRl: true,
          title: "Proteção do braço",
          description:
            "Puxe a criança por um braço na posição supina (estabilize o quadril contralateral) e observe a reação do braço no lado oposto",
          scores: {
            3: {
              title: "Braço e mão estendidos",
              img: "reacao_braco_3",
              rl: 1,
            },
            2: { title: "", img: "" , rl: 1},
            1: {
              title: "Braço semiflexionado",
              img: "reacao_braco_1",
              rl: 1,
            },
            0: {
              title: "Braço completamente flexionado",
              img: "reacao_braco_0",
              rl: 1,
            },
          },

          selectedScore: -1,
        },
        {
          key: Keys.Reactions + 100 + 2,
          title: "Suspensão vertical",
          description:
            "Segure a criança embaixo das axilas, não deixando que as pernas toquem na superfície – você pode fazer “cócegas” nos pés para estimular os chutes",
          scores: {
            3: { title: "Chuta simetricamente", img: "reacao_vertical_3" },
            2: { title: "", img: "" },
            1: {
              title: "Chuta mais uma perna ou chutes fracos",
              img: "reacao_vertical_1",
            },
            0: {
              title: "Não chuta mesmo que estimulado ou em posição de tesoura",
              img: "reacao_vertical_0",
            },
          },

          selectedScore: -1,
        },
        {
          title: "Inclinação lateral",
          description:
            "(descreva o lado que está para cima). Segure a criança verticalmente próximo dos quadris e incline lateralmente em direção horizontal. Observe a resposta do tronco, coluna, membros e cabeça",
          key: Keys.Reactions + 100 + 3,
          hasRl: true,
          scores: {
            3: { title: "", img: "reacao_lateral_3", rl: 1 },
            2: { title: "", img: "reacao_lateral_2", rl: 1 },
            1: { title: "", img: "reacao_lateral_1", rl: 1 },
            0: { title: "", img: "reacao_lateral_0", rl: 1 },
          },

          selectedScore: -1,
        },
        {
          title: "Paraquedas anterior",
          description:
            "Segure a criança verticalmente e rapidamente incline-a para frente. Observe a reação/simetria da resposta dos braços",
          key: Keys.Reactions + 100 + 4,
          scores: {
            3: { title: "(após 6 meses)", img: "reacao_anterior_3" },
            2: { title: "", img: "" },
            1: { title: "(após 6 meses)", img: "reacao_anterior_1" },
            0: { title: "", img: "" },
          },

          selectedScore: -1,
        },
        {
          title: "Reflexos tendinosos",
          description:
            "Com a criança relaxada, sentada ou deitada – use um martelo pequeno",
          key: Keys.Reactions + 100 + 5,
          hasRl: true,
          scores: {
            3: {
              title:
                "Reflexos normais. Facilmente excitável, bíceps, joelho, tornozelo",
              img: "",
              rl: 1,
            },
            2: {
              title:
                "Reflexos hipoativos. Levemente ativo, bíceps, joelho, tornozelo",
              img: "",
              rl: 1,
            },
            1: {
              title: "Reflexos hiperativos. Ativo, bíceps, joelho, tornozelo",
              img: "",
              rl: 1,
            },
            0: {
              title: "Clônus ou ausente, bíceps, joelho, tornozelo",
              img: "",
              rl: 1,
            },
          },

          selectedScore: -1,
        },
      ],
      ass: false,
      assComment: "",
    },
  ],
};

export const section2 = {
  title: "SEÇÃO 2: MARCOS MOTORES",
  description: "(não pontuados; observe assimetrias)",
  key: "section2",
  topics: [
    {
      title: "Controle da cabeça",
      key: Keys.Cabeca,
      scores: {
        0: {
          title: "Incapaz de manter a cabeça levantada. Normal aos 3 meses",
          img: "",
        },
        1: {
          title: "Oscilações. Normal até 4 meses",
          img: "",
        },
        2: {
          title:
            "Mantém a cabeça ereta todo o tempo. Normal a partir de 5 meses.",
          img: "",
        },
        3: { title: "", img: "" },
        4: { title: "", img: "" },
      },
      ass: false,
      assComment:
        "Por favor, anotar idade em que a habilidade máxima é alcançada",
      selectedScore: -1,
    },
    {
      title: "Sentar",
      key: Keys.Sentar,
      scores: {
        0: {
          title: "Nao pode sentar",
          img: "",
        },
        1: {
          title: "Com suporte nos quadris. Normal aos 4 meses",
          img: "sec02_motores_sentar01",
        },
        2: {
          title: "Escorado. Normal aos 6 meses",
          img: "sec02_motores_sentar02",
        },
        3: {
          title: "Senta-se estável. Normal aos 7-8 meses",
          img: "sec02_motores_sentar03",
        },
        4: {
          title: "Pivoteia (gira). Normal aos 9 meses",
          img: "sec02_motores_sentar04",
        },
      },
      selectedScore: -1,
      ass: false,
      assComment: "",
    },
    {
      title: "Preensão voluntária, observe o lado",
      key: Keys.Prensao,
      scores: {
        0: {
          title: "Sem preensão (Direita esquerda)",
          img: "",
        },
        1: {
          title: "Usa toda mão (Direita esquerda)",
          img: "",
        },
        2: {
          title:
            "Dedo indicador e polegar, mas preensão imatura (Direita esquerda)",
          img: "",
        },
        3: {
          title: "Preensão em pinça (Direita esquerda)",
          img: "",
        },
        4: { title: "", img: "" },
      },
      selectedScore: -1,
      ass: false,
      assComment: "",
    },
    {
      title: "Habilidade de chutar em supino",
      key: Keys.Habilidade,
      scores: {
        0: {
          title: "Não chuta",
          img: "",
        },
        1: {
          title: "Chuta horizontalmente, mas não eleva as pernas",
          img: "",
        },
        2: {
          title: "Para cima (verticalmente). Normal aos 3 meses",
          img: "sec02_motores_chutar01",
        },
        3: {
          title: "Toca a perna. Normal aos 4-5 meses",
          img: "sec02_motores_chutar02",
        },
        4: {
          title: "Toca nos dedos dos pés. Normal aos 5-6 meses",
          img: "sec02_motores_chutar03",
        },
      },
      selectedScore: -1,
      ass: false,
      assComment: "",
    },
    {
      title: "Rolar - Observe para qual lado",
      key: Keys.Rolar,
      scores: {
        0: {
          title: "Não rola",
          img: "",
        },
        1: {
          title: "Rola para o lado. Normal aos 4 meses",
          img: "",
        },
        2: {
          title: "Rola de prono para supino. Normal aos 6 meses",
          img: "",
        },
        3: {
          title: "Rola de supino para prono. Normal aos 6 meses",
          img: "",
        },
        4: { title: "", img: "" },
      },
      ass: false,
      assComment: "",
    },
    {
      title: "Engatinhar - observa se arrasta sentado",
      key: Keys.Engatinhar,
      scores: {
        0: {
          title: "Não levanta a cabeça",
          img: "",
        },
        1: {
          title: "Com cotovelo. Normal aos 3 meses",
          img: "sec02_motores_engatinhar01",
        },
        2: {
          title: "Com os braços e mãos super estendidos. Normal aos 4 meses",
          img: "sec02_motores_engatinhar02",
        },
        3: {
          title: "Engatinha com abdômen. Normal aos 8 meses",
          img: "sec02_motores_engatinhar03",
        },
        4: {
          title: "Engatinha sobre mãos e joelhos. Normal aos 10 meses",
          img: "sec02_motores_engatinhar04",
        },
      },
      ass: false,
      assComment: "",
    },
    {
      title: "Em pé",
      key: Keys.EmPe,
      scores: {
        0: {
          title: "Não sustenta o peso",
          img: "",
        },
        1: {
          title: "Sustenta o peso. Normal aos 4 meses",
          img: "",
        },
        2: {
          title: "Em pé com apoio. Normal aos 7 meses",
          img: "",
        },
        3: {
          title: "Em pé sem ajuda. Normal aos 12 meses",
          img: "",
        },
        4: { title: "", img: "" },
      },
      ass: false,
      assComment: "",
      selectedScore: -1,
    },
    {
      title: "Marcha",
      key: Keys.Marcha,
      scores: {
        0: {
          title: "",
          img: "",
        },
        1: {
          title: "Saltando. Normal aos 6 meses",
          img: "",
        },
        2: {
          title: "Marcha lateral (se segurando). Normal aos 12 meses",
          img: "",
        },
        3: {
          title: "Marcha independente. Normal aos 15 meses",
          img: "",
        },
        4: { title: "", img: "" },
      },
      ass: false,
      assComment: "",
      selectedScore: -1,
    },
  ],
};

export const section3 = {
  title: "SEÇÃO 3: COMPORTAMENTO",
  description: "(não pontuado)",
  key: "section3",
  topics: [
    {
      title: "Estado consciente",
      key: Keys.Conciente,
      scores: {
        1: {
          title: "Irritado",
          img: "",
        },
        2: {
          title: "Sonolento",
          img: "",
        },
        3: {
          title: "Dormido, mas acorda facilmente",
          img: "",
        },
        4: {
          title: "Acordado, mas sem interesse",
          img: "",
        },
        5: {
          title: "Perde interesse",
          img: "",
        },
        6: {
          title: "Mantém interesse",
          img: "",
        },
      },
      ass: false,
      assComment: "",
      selectedScore: -1,
    },
    {
      title: "Estado emocional",
      key: Keys.Emocional,
      scores: {
        1: {
          title: "Irritado, não consolável",
          img: "",
        },
        2: {
          title: "Irritável, cuidador pode consolar",
          img: "",
        },
        3: {
          title: "Irritável quando se aproxima",
          img: "",
        },
        4: {
          title: "Nem feliz ou infeliz",
          img: "",
        },
        5: {
          title: "Feliz e sorridente",
          img: "",
        },
        6: { title: "", img: "" },
      },
      ass: false,
      assComment: "",
      selectedScore: -1,
    },
    {
      title: "Orientação social",
      key: Keys.Orientacao,
      scores: {
        1: {
          title: "Evitando, retirando",
          img: "",
        },
        2: {
          title: "Hesitante",
          img: "",
        },
        3: {
          title: "Aceita aproximação",
          img: "",
        },
        4: {
          title: "Amigável",
          img: "",
        },
        5: { title: "", img: "" },
        6: { title: "", img: "" },
      },
      ass: false,
      assComment: "",
      selectedScore: -1,
    },
  ],
};

export const HineFormTemplate = {
  patient: {},
  evaluator: {},
  hine: [section1, section2, section3],
  hine2: [section2, section3],
  id: 0,
  date: "",
  score: 0,
  currentHine: "",
  currentSection: "",
  currentSubsection: "",
  assimetrias: 0,
};

const addIdToASetOfTopics = (topics) => {
  return [...topics].map((topic) => {
    const newTopic = { ...topic };
    if (newTopic.key === 14) {
      console.log("newTopic");
      console.log(newTopic);
      console.log("scores");
      console.log([...Object.keys(newTopic.scores)].map((score, i) => score));
    }
    newTopic.scores = [...Object.keys(newTopic.scores)].map((score, i) => {
      newTopic.scores[score].id = `${newTopic.key}_${score}`;
      newTopic.scores[score]["score"] = score;
      if (Object.keys(newTopic.scores[score]).includes("multiple")) {
        newTopic.scores[score].multiple = newTopic.scores[score].multiple.map(
          (multipleEntry, i) => {
            multipleEntry.id = `${newTopic.key}_${score}_${i}`;
            return multipleEntry;
          }
        );
      }

      if (Object.keys(newTopic.scores[score]).includes("rl")) {
        newTopic.scores[score].idIzquerda = `${newTopic.key}_${score}_izquerda`;
        newTopic.scores[score].idDireita = `${newTopic.key}_${score}_direita`;
      }

      return newTopic.scores[score];
    });

    return newTopic;
  });
};

const addIdToASetOfSections = (sections) => {
  return [...sections].map((sect) => {
    const newSect = { ...sect };
    if (newSect.key === "section1") {
      newSect.subsections = [
        ...newSect.subsections.map((subsect) => {
          const newSubSect = { ...subsect };
          newSubSect.topics = [...addIdToASetOfTopics([...newSubSect.topics])];
          return newSubSect;
        }),
      ];
    } else {
      newSect.topics = [...addIdToASetOfTopics([...sect.topics])];
    }
    return newSect;
  });
};

HineFormTemplate.hine = addIdToASetOfSections([...HineFormTemplate.hine]);
HineFormTemplate.hine2 = addIdToASetOfSections([...HineFormTemplate.hine2]);

console.log(HineFormTemplate);

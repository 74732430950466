import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MaterialUIControllerProvider } from "context";
import App from "App";
import store from "./store";
import { Provider } from "react-redux";
import { LOCALSTORAGE_KEY } from "./reducers";

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

store.subscribe(() => {
  const state = store.getState();
  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(state));
});

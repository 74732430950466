/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "../../../main-menu/bg.jpeg";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import env from "react-dotenv";
import { clear } from "reducers/auth";

function Cover() {
  const [data, setData] = useState({ email: "" });
  const navigate = useNavigate();
  const customErrorMessage = "Erro ao resetar, tente mais tarde";
  const [showError, setShowError] = useState(false);
  const disp = useDispatch();

  const reset = () => {
    const url = `${env.API_URL}/auth/forgot-password`;
    axios
      .post(url, data)
      .then((resetResp) => {
        alert("Email enviado com sucesso!")
      })
      .catch((err) => {
        disp(clear());
        setShowError(true);
        alert(err);
      });
  };
  
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Resete sua senha
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Você receberá um email em até 2 minutos
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput onChange={(evt) => setData({ email: evt.target.value })} type="email" label="Email" variant="standard" fullWidth />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton onClick={reset} variant="gradient" color="info" fullWidth>
                Resetar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;

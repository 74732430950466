import jsPDF from "jspdf";
import fapeamLogo from "../main-menu/fapeam.png";
import hineLogo from "../main-menu/hine.png";
import labtamLogo from "../main-menu/labtam.png";
import ufamlogo from "../main-menu/ufam.png";

import moment from "moment";
import { section1 } from "reducers/data_last";

import autoTable from 'jspdf-autotable'

export default function createAvaliacaoPdf(
    name,
    patientData,
    hineData,
    recordDate,
    doctorData
  ) {
    console.log(hineData);

    const sect1 = hineData.hine[0];
    console.log(sect1)
    console.log("############")
    const doc = new jsPDF("p", "pt", "a4", false);
    doc.setFontSize(10);
  
    doc.addImage(hineLogo, "PNG", 10, 10, 250, 50);
  
    doc.addImage(ufamlogo, "PNG", 415, 10, 30, 30);
    doc.addImage(fapeamLogo, "PNG", 455, 10, 60, 30);
    doc.addImage(labtamLogo, "PNG", 525, 10, 60, 30);
  
    const ident = 30;
    const identStep = 10;
  
    const textInitY = 100;
    const lineJumpStep = 30;
    const lineJumpHalfStep = 20;
  
    const pontuacao = parseFloat(sect1?.points) || 0;
  
    const monthsDiff = moment().diff(patientData.birthDate, "months");
  
    const otima =
      (monthsDiff <= 3 && pontuacao >= 56) ||
      (monthsDiff <= 6 && pontuacao >= 59) ||
      (monthsDiff <= 9 && pontuacao >= 62) ||
      (monthsDiff <= 12 && pontuacao >= 65);
  
    const textoOtimo =
      "ausência de risco para alteração neuromotora/paralisia cerebral.";
    const textoSubOtimo =
      "predição de risco para alteração neuromotora/paralisia cerebral.";
  
    let sectionIdent = ident;
    doc.text("INFORMAÇÕES DO PACIENTE:", sectionIdent, textInitY);
    sectionIdent = ident + identStep;
    doc.text(`NOME: ${patientData.name}`, sectionIdent, textInitY + lineJumpStep);
    doc.text(
      `DATA DE NASCIMENTO: ${moment(patientData.birthDate).format("YYYY-MM-DD")}`,
      sectionIdent,
      textInitY + lineJumpStep + lineJumpHalfStep
    );
  
    doc.text(
      `DATA DA AVALIAÇÃO: ${moment(recordDate).format("YYYY-MM-DD HH:MM")}`,
      sectionIdent,
      textInitY + lineJumpStep + lineJumpHalfStep * 2
    );
  
    doc.text(
      `AVALIADOR(A): ${doctorData.name} ${doctorData.email}`,
      sectionIdent,
      textInitY + lineJumpStep + lineJumpHalfStep * 3
    );
  
    doc.text(
      `IDADE GESTACIONAL: ${
        patientData?.idadeGestacional || "Desconhecido"
      } (semanas)`,
      sectionIdent,
      textInitY + lineJumpStep + lineJumpHalfStep * 4
    );
  
    doc.text(
      `IDADE CRONOLÓGICA: ${patientData?.idadeCronologica || "Desconhecido"}`,
      sectionIdent,
      textInitY + lineJumpStep + lineJumpHalfStep * 5
    );
  
    doc.text(
      `IDADE CORRIGIDA: ${patientData?.idadeCorrigida || "Desconhecido"}`,
      sectionIdent,
      textInitY + lineJumpStep + lineJumpHalfStep * 6
    );
  
    sectionIdent = ident;
    doc.text(
      `RESUMO DA AVALIAÇÃO:`,
      sectionIdent,
      textInitY + lineJumpStep + lineJumpHalfStep * 7
    );

    sect1.subsections.forEach(
        (obj) => {
            var keyBusca = obj.key
            var categoria = section1.subsections.find(obj => obj.key === keyBusca) //subsecao
            console.log("obj: " + categoria.title)

            obj.topics.forEach(
                (topic) => {
                    var keyBusca = topic.key
                    var titulo = categoria.topics.find(obj => obj.key === keyBusca) //topicos
                    var points = topic.points
                    console.log("topic: " + titulo.title)

                    var selectedScoreElement = topic.scores.find(score => score.id === topic.selectedScore);
                    if (selectedScoreElement) {
                      console.log("Selected Score Title: ", selectedScoreElement);

                      section1.subsections.forEach(subsectionAux => {
                        // Iterate through each topic in the subsection
                        subsectionAux.topics.forEach(topicAux => {
                            // Check if the topic's key matches the selectedScoreKey
                            if (topicAux.key === selectedScoreElement.key) {
                                console.log("Found matching topic in section1:", topic);
                                // Access the selected score within the topic's scores object
                                const matchingScore = topicAux.scores[selectedScoreElement.key];
                                console.log("Matching score:", matchingScore);    
                            }
                        });
                      });
                    }else{
                      console.log("sem resposta")
                    }

                    if(titulo === "Aparência facial"){
                      console.log("Aparencia ... ", topic)
                    }
                }
            )
          }
    )  

    // Prepare data for the table
    const tableColumns = ['Question', 'Answer', 'Score'];
    const tableRows = [
      ['Question 1', 'Answer 1', 90],
      ['Question 2', 'Answer 2', 85],
      ['Question 1', 'Answer 1', 90],
      ['Question 2', 'Answer 2', 85],
      ['Question 1', 'Answer 1', 90],
      ['Question 2', 'Answer 2', 85],
      ['Question 1', 'Answer 1', 90],
      ['Question 2', 'Answer 2', 85],
      ['Question 1', 'Answer 1', 90],
      ['Question 2', 'Answer 2', 85],
      ['Question 1', 'Answer 1', 90],
      ['Question 2', 'Answer 2', 85],
      ['Question 1', 'Answer 1', 90],
      ['Question 2', 'Answer 2', 85],
      ['Question 1', 'Answer 1', 90],
      ['Question 2', 'Answer 2', 85],
      ['Question 1', 'Answer 1', 90],
      ['Question 2', 'Answer 2', 85],
      ['Question 1', 'Answer 1', 90],
      ['Question 2', 'Answer 2', 85],
      ['Question 1', 'Answer 1', 90],
      ['Question 2', 'Answer 2', 85],
      ['Question 1', 'Answer 1', 90],
      ['Question 2', 'Answer 2', 85],
      // Add more rows as needed
    ];

    // Set the table options
    const tableOptions = {
      startY: textInitY + lineJumpStep + lineJumpHalfStep * 7 + lineJumpStep,
      // Adjust startY to place the table right after the last header text
      //textInitY + lineJumpStep + lineJumpHalfStep * 6 + lineJumpStep * 2
      theme: 'grid',
      head: [tableColumns],
      body: tableRows,
    };

    // Generate the table
    autoTable(doc, tableOptions);
  
    doc.save(`${name}.pdf`);
  }
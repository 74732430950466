import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import React, { useState } from "react";

import { CircularProgress } from "@mui/material";

function ResidenceUserForm({ nextHandler, prevHandler, save }) {
  const [formData, setFormData] = useState({
    address: "",
    phone: "",
    cpf: "",
    rg: "",
  });

  const [loading, setLoading] = useState(false);
  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Dados Recidenciais
        </MDTypography>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ mt: 1, mb: 2 }}
        ></Grid>
      </MDBox>
      <MDBox pt={1} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={2} mt={2}>
            <MDInput
              label="Endereco"
              variant="standard"
              value={formData.address}
              onChange={(ev) => {
                setFormData({ ...formData, address: ev.target.value });
              }}
              required={true}
              fullWidth
            />
          </MDBox>
          <MDBox mb={2} mt={2}>
            <MDInput
              label="Telefone"
              variant="standard"
              type="phone"
              value={formData.phone}
              onChange={(ev) => {
                setFormData({ ...formData, phone: ev.target.value });
              }}
              fullWidth
            />
          </MDBox>
          <MDBox mb={2} mt={2}>
            <MDInput
              label="CPF"
              variant="standard"
              type="number"
              required={true}
              value={formData.cpf}
              onChange={(ev) => {
                setFormData({ ...formData, cpf: ev.target.value });
              }}
              fullWidth
            />
          </MDBox>
          <MDBox mb={2} mt={2}>
            <MDInput
              label="RG"
              value={formData.rg}
              onChange={(ev) => {
                setFormData({ ...formData, rg: ev.target.value });
              }}
              variant="standard"
              type="number"
              fullWidth
            />
          </MDBox>
          <MDBox mt={4}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                prevHandler(formData);
              }}
              fullWidth
            >
              Anterior
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mt={2} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              nextHandler(formData);
            }}
            fullWidth
          >
            Seguinte
          </MDButton>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              save({ ...formData }, true, setLoading);
            }}
            fullWidth
          >
            {loading ? <CircularProgress /> : <b>Salvar e ir para Hine</b>}
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ResidenceUserForm;

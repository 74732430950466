// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";

function Footer({ light }) {
  const { size } = typography;

  return (
    <MDBox position="" width="100%" bottom={0} py={0} display="grid" justifyContent="center">
      <Container>
        {true && (
          <MDBox
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="space-between"
            alignItems="center"
            px={1.5}
          >
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              color={light ? "white" : "text"}
              fontSize={size.sm}
            >
              &copy; {new Date().getFullYear()}, projeto desenvolvido numa parceria 
              <MDBox
                fontSize={size.md}
                color={light ? "white" : "dark"}
                
              >
                
              </MDBox>
              
              <Link href="https://roj4s.com" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  color={light ? "white" : "dark"}
                >
                  &nbsp;Roj4s&nbsp;
                </MDTypography>
              </Link>
              ,
              <Link href="https://ctic.ufam.edu.br" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  color={light ? "white" : "dark"}
                >
                  &nbsp;CTIC - UFAM&nbsp;
                </MDTypography>
              </Link>
              e 
              <Link href="https://www.fapeam.am.gov.br/" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  color={light ? "white" : "dark"}
                >
                  &nbsp;Fapeam&nbsp;
                </MDTypography>
              </Link>
            </MDBox>
          </MDBox>
        )}
      </Container>
    </MDBox>
  );
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;

import PageLayout from "examples/LayoutContainers/PageLayout";
import MDButton from "components/MDButton";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { setCurrentPatient, setCurrentHine } from "../../reducers";
import { Alert, Autocomplete, Snackbar, TextField } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import env from "react-dotenv";

import moment from "moment";

const SAVE_STATE = {
  NOT_SENT: 0,
  SUCCESS: 1,
  ERROR: 2,
};

function HineMenuPage() {
  const disp = useDispatch();
  const hineData = useSelector((state) => state.hine.data);
  const currentPatient = useSelector((state) => state.hine.currentPatient);
  const currentUuid = useSelector((state) => state.hine.currentUuid);
  const [patients, setPatients] = useState([]);
  const [id, setId] = useState(null);
  const authData = useSelector((state) => state.auth.data);
  const navigate = useNavigate();
  const [curPat, setCurPat] = useState(null);
  const [saveStatus, setSaveStatus] = useState(SAVE_STATE.NOT_SENT);

  useEffect(() => {
    if (currentPatient) {
      const cur = patients.find((p) => p._id === currentPatient);
      if (cur) {
        setCurPat({ ...cur, label: cur.name });
      }
    }
  }, [currentPatient, patients]);

  useEffect(() => {
    if (!authData.logedIn) {
      navigate("/");
    }
  }, [authData, navigate]);

  useEffect(() => {
    const url = `${env.API_URL}/admin/patient/getByDoctor/${authData.userId}?secret_token=${authData.token}`;
    axios.get(url).then((resp) => {
      setPatients(resp.data);
      console.log("patients", resp.data);
    });

    // disp(setCurrentPatient(null));
  }, [authData.token, authData.userId, disp]);

  const save = () => {
    const url = `${env.API_URL}/admin/record?secret_token=${authData.token}`;
    const data = JSON.stringify(hineData[currentPatient]);

    const body = {
      uuid: currentUuid,
      doctor: authData.userId,
      date: moment(),
      patient: currentPatient,
      data,
    };

    setSaveStatus(SAVE_STATE.NOT_SENT);

    if (id !== null) {
      axios
        .put(`${url}/${id}`, body)
        .then((resp) => {
          setSaveStatus(SAVE_STATE.SUCCESS);
          setTimeout(() => setSaveStatus(SAVE_STATE.NOT_SENT), 6000);
        })
        .catch((err) => {
          setSaveStatus(SAVE_STATE.ERROR);
        });
    } else {
      axios
        .post(url, body)
        .then((resp) => {
          setSaveStatus(SAVE_STATE.SUCCESS);
          setTimeout(() => setSaveStatus(SAVE_STATE.NOT_SENT), 6000);
          setId(resp.data._id);
        })
        .catch((err) => {
          setSaveStatus(SAVE_STATE.ERROR);
        });
    }
  };

  useEffect(() => {
    setPatients(Object.keys(hineData).map((k) => hineData[k].patient));
  }, [hineData]);

  useEffect(() => {
    console.log(patients);
  }, [patients]);

  return (
    <PageLayout>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={saveStatus === SAVE_STATE.SUCCESS}
      >
        <Alert severity="success" variant="filled">
          As mudancas foram salvas
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={saveStatus === SAVE_STATE.ERROR}
      >
        <Alert severity="danger" variant="filled">
          Houve um erro ao salvar as mudancas
        </Alert>
      </Snackbar>
      <DefaultNavbar
        action={{
          type: "external",
          route: "https://google.com",
          label: "free download",
          color: "dark",
        }}
        light={false}
        fullWidth
      />

      {patients.length ? (
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
            margin: "10px",
          }}
          fullWidth
        >
          <Grid
            container
            spacing={2}
            width={{ lg: "50%", xs: "100%", md: "100%", sm: "100%" }}
            fullWidth
          >
            <Grid item xs={12}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <Autocomplete
                  options={patients.map((p) => {
                    return { ...p, label: p.name };
                  })}
                  value={curPat}
                  isOptionEqualToValue={(opt, val) => opt._id === val._id}
                  renderInput={(params) => (
                    <TextField {...params} label="Paciente" />
                  )}
                  onChange={(_, val) => {
                    disp(setCurrentPatient(val._id));
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Link
                to="/hine-sections-menu"
                state={{ isSectionsMenu: true, isSubSectionsMenu: false }}
              >
                <MDButton
                  sx={{ height: "80px" }}
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    disp(setCurrentHine("hine"));
                  }}
                  fullWidth
                >
                  HINE
                </MDButton>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Link
                to="/hine-sections-menu"
                state={{ isSectionsMenu: true, isSubSectionsMenu: false }}
              >
                <MDButton
                  variant="gradient"
                  sx={{ flex: 1, height: "80px" }}
                  color="info"
                  onClick={() => {
                    disp(setCurrentHine("hine2"));
                  }}
                  fullWidth
                >
                  HINE 2 AME
                </MDButton>
              </Link>
            </Grid>
            {currentPatient !== null && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MDButton
                  variant="gradient"
                  sx={{ flex: 1, height: "80px" }}
                  color="info"
                  onClick={save}
                  fullWidth
                >
                  Salvar
                </MDButton>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Link to="/">
                <MDButton
                  variant="gradient"
                  sx={{ flex: 1, height: "80px" }}
                  color="info"
                  fullWidth
                >
                  Voltar a Menu Inicial
                </MDButton>
              </Link>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          fullWidth
        >
          <div>Nenhum paciente encontrado</div>
          <Link
            to="/user-form"
            state={{ isSectionsMenu: true, isSubSectionsMenu: false }}
          >
            <MDButton
              variant="gradient"
              sx={{ flex: 1, height: "50px" }}
              color="info"
              fullWidth
            >
              Adicionar paciente
            </MDButton>
          </Link>
          <Link to="/">
            <MDButton
              variant="gradient"
              sx={{ flex: 1, height: "80px" }}
              color="info"
              fullWidth
            >
              Voltar a Menu Inicial
            </MDButton>
          </Link>
        </MDBox>
      )}
    </PageLayout>
  );
}

export default HineMenuPage;

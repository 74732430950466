import { createSlice } from "@reduxjs/toolkit";
import { LOCALSTORAGE_KEY } from "reducers";

const lsData = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY))?.auth;

const initialState = lsData || {
  data: { email: "", token: "" },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.data = action.payload;
    },
    clear: (state) => {
      state.data = { email: "", token: "" };
    },
  },
});

export const { setAuth, clear } = authSlice.actions;

export default authSlice.reducer;

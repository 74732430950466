import { createSlice } from "@reduxjs/toolkit";
import { HineFormTemplate } from "./data_last";

export const LOCALSTORAGE_KEY = "e-hine";

const initState = JSON.parse(localStorage.getItem("hine"))?.hine ?? {
  currentPatient: null,
  currentHine: null,
  currentSection: null,
  currentSubsection: null,
  data: {},
  currentUuid: null,
};

export const hineSlice = createSlice({
  name: "hine",
  initialState: initState,
  reducers: {
    clearHine: (state) => {
      state.currentPatient = null;
      state.currentHine = null;
      state.currentSection = null;
      state.currentSubsection = null;
      state.data = {};
      state.currentUuid = null;
    },
    setCurrentUuid: (state, action) => {
      state.currentUuid = action.payload;
    },
    setCurrentPatient: (state, action) => {
      if (action.payload !== null) {
        state.currentPatient = action.payload;
        state.data[action.payload] = {
          ...HineFormTemplate,
          patient: { ...action.payload },
        };
      }
    },
    setCurrentHine: (state, action) => {
      state.currentHine = action.payload;
    },
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
    },
    setCurrentSubSection: (state, action) => {
      state.currentSubsection = action.payload;
    },
    setPatients: (state, action) => {
      const data = {};
      for (const pat of action.payload) {
        const patientId = pat._id;
        if (!Object.keys(state.data).includes(patientId)) {
          data[patientId] = {
            ...HineFormTemplate,
            patient: { ...pat },
          };
        }
      }

      state.data = { ...state.data, ...data };
    },

    updateData: (state, action) => {
      const payl = action.payload;
      const newData = { ...state.data };
      if (payl.ass !== null) {
        let currentAss = newData[payl.patientId]?.assimetrias || 0;
        if (payl.ass) {
          currentAss += 1;
        } else if (currentAss !== 0) {
          currentAss -= 1;
        }

        newData[payl.patientId]["assimetrias"] = currentAss;
      }
      newData[payl.patientId][payl.hine] = newData[payl.patientId][
        payl.hine
      ].map((sect) => {
        const newSect = { ...sect };
        let sectionPoints = 0;
        if (newSect.key === payl.section) {
          if (payl.section !== "section1") {
            newSect.topics = newSect.topics.map((topic) => {
              let topicPoints = topic?.points || 0;
              if (topic.key === payl.key) {
                const topicData = { ...topic };
                if (payl.score !== null) {
                  topicData[payl.selectedScoreKey] = payl.score;
                  const selectedScoreData = topicData.scores[payl.actualScore];
                  const hastSplitEachRl = selectedScoreData?.splitEachRl;
                  const hasRl = selectedScoreData?.rl;

                  let points = parseInt(payl.actualScore);
                  let currentPoints = topicData?.points || 0;
                  if (hastSplitEachRl || hasRl) {
                    points /= 2;
                  }

                  let reduceFactor = 0;

                  if (payl.score.includes("izquerda")) {
                    reduceFactor = topicData?.reduceFactorIzq || 0;
                    topicData["reduceFactorIzq"] = points;
                  } else if (payl.score.includes("direita")) {
                    reduceFactor = topicData?.reduceFactorDir || 0;
                    topicData["reduceFactorDir"] = points;
                  } else {
                    reduceFactor = topicData?.reduceFactor || 0;
                    topicData["reduceFactor"] = points;
                  }

                  currentPoints = currentPoints + points - reduceFactor;
                  topicPoints = currentPoints;
                  topicData[payl.selectedScoreKey] = payl.score;

                  topicData["points"] = currentPoints;
                }
                if (payl.assimetry !== null) {
                  topicData.ass = payl.assimetry;
                }
                sectionPoints += topicPoints;
                return topicData;
              }
              sectionPoints += topicPoints;
              return topic;
            });
          } else {
            newSect.subsections = newSect.subsections.map((subsect) => {
              const newSubsect = { ...subsect };
              let subSectionPoints = newSubsect?.points || 0;
              if (newSubsect.key === payl.subSection) {
                subSectionPoints = 0;
                newSubsect.topics = newSubsect.topics.map((topic) => {
                  let topicPoints = topic?.points || 0;
                  if (topic.key === payl.key) {
                    const newTopic = { ...topic };
                    if (payl.score !== null) {
                      const selectedScoreData =
                        newTopic.scores[payl.actualScore];
                      const hastSplitEachRl = selectedScoreData?.splitEachRl;
                      const hasRl = selectedScoreData?.rl;

                      let points = parseInt(payl.actualScore);
                      let currentPoints = newTopic?.points || 0;
                      if (hastSplitEachRl) {
                        points /= 2;  //sempre eh 2 independente se tem 2 elementos no box D ou E
                      } else if (hasRl) {
                        points /= 2;
                      }

                      let reduceFactor = 0;

                      if (payl.score.includes("izquerda")) {
                        reduceFactor = newTopic?.reduceFactorIzq || 0;
                        newTopic["reduceFactorIzq"] = points;
                      } else if (payl.score.includes("direita")) {
                        reduceFactor = newTopic?.reduceFactorDir || 0;
                        newTopic["reduceFactorDir"] = points;
                      } else {
                        reduceFactor = newTopic?.reduceFactor || 0;
                        newTopic["reduceFactor"] = points;
                      }

                      currentPoints = currentPoints + points - reduceFactor;
                      topicPoints = currentPoints;
                      newTopic[payl.selectedScoreKey] = payl.score;

                      newTopic["points"] = currentPoints;
                    }
                    if (payl.assimetry !== null) {
                      newTopic.ass = payl.assimetry;
                    }
                    subSectionPoints += topicPoints;
                    return newTopic;
                  }
                  subSectionPoints += topicPoints;
                  return topic;
                });
              }

              newSubsect["points"] = subSectionPoints;
              sectionPoints += subSectionPoints;
              return newSubsect;
            });
          }
        }
        newSect["points"] = sectionPoints;
        return newSect;
      });

      state.data = { ...newData };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentPatient,
  setCurrentHine,
  setCurrentSubSection,
  setCurrentSection,
  setPatients,
  updateData,
  setCurrentUuid,
  clearHine,
} = hineSlice.actions;

export default hineSlice.reducer;

import { Link } from "react-router-dom";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import CoverLayout from "layouts/authentication/components/CoverLayout";

// import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import bgImage from "../../main-menu/bg.jpeg";

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { setAuth, clear } from "../../../reducers/auth";

import { useDispatch } from "react-redux";
import { Fade } from "@mui/material";
import BasicLayout from "../components/BasicLayout";
import env from "react-dotenv";
import hine_image from "../../main-menu/hine.png";
// import env from "react-dotenv";

function Cover() {
  const [data, setData] = useState({ email: "", password: "", name: "" });
  const [showError, setShowError] = useState(false);
  const customErrorMessage = "Erro criando conta, tente mais tarde";
  const navigate = useNavigate();
  const disp = useDispatch();

  const signUp = () => {
    const url = `${env.API_URL}/auth/signup`;
    axios
      .post(url, data)
      .then((resp) => {
        const signinurl = `${env.API_URL}/auth/login`;
        if (resp.status === 200) {
          axios
            .post(signinurl, data)
            .then((loginResp) => {
              if (loginResp.status === 200) {
                const authData = {
                  email: loginResp.data.body.email,
                  logedIn: true,
                  token: loginResp.data.token,
                  userId: loginResp.data.body._id,
                };

                disp(setAuth(authData));
                navigate("/");
              } else {
                disp(clear());
                setShowError(true);
              }
            })
            .catch((loginErr) => {
              setShowError(true);
            });
        }
      })
      .catch((err) => {
        setShowError(true);
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card style={{paddingBottom: 20}}>
      <img
          style={{ margin: "10px", marginBottom: "50px" }}
          src={hine_image}
          alt="logo"
        />
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Nova conta
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Entre seu email e uma senha para criar sua conta
          </MDTypography>
        </MDBox>
        <MDBox px={3} pt={3} component="form" role="form">
          <MDBox mb={2}>
            <MDInput
              type="text"
              value={data.name}
              label="Nome"
              fullWidth
              required
              onChange={(evt) => {
                setData({ ...data, name: evt.target.value });
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              fullWidth
              value={data.email}
              required
              onChange={(evt) => {
                setData({ ...data, email: evt.target.value });
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="Senha"
              value={data.password}
              onChange={(evt) => {
                setData({ ...data, password: evt.target.value });
              }}
              fullWidth
              required
            />
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={signUp}
            >
              Criar conta
            </MDButton>
          </MDBox>
          <MDBox mt={3} textAlign="center">
            <MDTypography variant="button" color="text">
              Ja tem uma conta?{" "}
              <MDTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Entrar
              </MDTypography>
            </MDTypography>
          </MDBox>        
        </MDBox>
        {showError && (
            // <MDBox mt={3} mb={1} textAlign="center">
            //   <MDTypography variant="button" color="red">
            //     {customErrorMessage}
            //   </MDTypography>
            // </MDBox>
            <Fade in={showError}>
              <MDBox variant="gradient" bgColor="info" mt={3} textAlign="center">
                <MDTypography variant="button" fontWeight="medium" color="white">
                  {customErrorMessage}
                </MDTypography>
              </MDBox>
            </Fade>
          )}
      </Card>
    </BasicLayout>
  );
}

export default Cover;

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import React, { useState } from "react";

import { CircularProgress } from "@mui/material";

function ClinicalDataUserForm({ nextHandler, prevHandler, save }) {
  const [formData, setFormData] = useState({
    forwardedBy: "",
    diagnosis: "",
    therapist: "",
    academic: "",
  });
  const [loading, setLoading] = useState(false);
  return (
    <Card fullWidth>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Dados Clinicos
        </MDTypography>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ mt: 1, mb: 2 }}
        ></Grid>
      </MDBox>
      <MDBox pt={1} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={2} mt={2}>
            <MDInput
              label="Encaminhado por"
              variant="standard"
              fullWidth
              value={formData.forwardedBy}
              onChange={(ev) => {
                setFormData({ ...formData, forwardedBy: ev.target.value });
              }}
            />
          </MDBox>
          <MDBox mb={2} mt={2}>
            <MDInput
              label="Diagnostico Clinico"
              variant="standard"
              value={formData.diagnosis}
              onChange={(ev) => {
                setFormData({ ...formData, diagnosis: ev.target.value });
              }}
              fullWidth
            />
          </MDBox>
          <MDBox mb={2} mt={2}>
            <MDInput
              label="Profissional Responsavel"
              value={formData.therapist}
              onChange={(ev) => {
                setFormData({ ...formData, therapist: ev.target.value });
              }}
              variant="standard"
              required={true}
              fullWidth
            />
          </MDBox>

          <MDBox mb={2} mt={2}>
            <MDInput
              label="Academico Responsavel"
              value={formData.academic}
              onChange={(ev) => {
                setFormData({ ...formData, academic: ev.target.value });
              }}
              variant="standard"
              fullWidth
            />
          </MDBox>
          <MDBox mt={4}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                prevHandler(formData);
              }}
              fullWidth
            >
              Anterior
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              save({ ...formData }, true, setLoading);
            }}
            fullWidth
          >
            {loading ? <CircularProgress /> : <b>Salvar e ir para Hine</b>}
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ClinicalDataUserForm;

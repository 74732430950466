import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import React, { useState } from "react";

import { CircularProgress } from "@mui/material";

function ParentUserForm({ nextHandler, prevHandler, save }) {
  const [formData, setFormData] = useState({
    fatherName: "",
    fatherProfission: "",
    fatherSchollarship: "",
    motherName: "",
    motherProfission: "",
    motherSchollarship: "",
    familyIncome: "",
  });

  const [loading, setLoading] = useState(false);

  return (
    <Card fullWidth>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Dados dos Pais
        </MDTypography>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ mt: 1, mb: 2 }}
        ></Grid>
      </MDBox>
      <MDBox sx={{ margin: 2 }}>
        <MDBox component="form" role="form">
          <MDBox>
            <MDInput
              label="Nome do Pai"
              variant="standard"
              required={true}
              value={formData.fatherName}
              onChange={(ev) => {
                setFormData({ ...formData, fatherName: ev.target.value });
              }}
              fullWidth
            />
          </MDBox>
          <MDBox mb={2} mt={2}>
            <MDInput
              label="Proffissao do Pai"
              variant="standard"
              value={formData.fatherProfission}
              onChange={(ev) => {
                setFormData({ ...formData, fatherProfission: ev.target.value });
              }}
              fullWidth
            />
          </MDBox>
          <MDBox mb={2} mt={2}>
            <MDInput
              label="Escolaridade do Pai"
              variant="standard"
              value={formData.fatherSchollarship}
              onChange={(ev) => {
                setFormData({
                  ...formData,
                  fatherSchollarship: ev.target.value,
                });
              }}
              fullWidth
            />
          </MDBox>

          <MDBox mb={2} mt={2}>
            <MDInput
              label="Nome da Mae"
              value={formData.motherName}
              onChange={(ev) => {
                setFormData({ ...formData, motherName: ev.target.value });
              }}
              variant="standard"
              required={true}
              fullWidth
            />
          </MDBox>

          <MDBox mb={2} mt={2}>
            <MDInput
              label="Proffissao da Mae"
              value={formData.motherProfission}
              onChange={(ev) => {
                setFormData({ ...formData, motherProfission: ev.target.value });
              }}
              variant="standard"
              fullWidth
            />
          </MDBox>
          <MDBox mb={2} mt={2}>
            <MDInput
              label="Escolaridade da Mae"
              value={formData.motherSchollarship}
              onChange={(ev) => {
                setFormData({
                  ...formData,
                  motherSchollarship: ev.target.value,
                });
              }}
              variant="standard"
              fullWidth
            />
          </MDBox>
          <MDBox mb={2} mt={2}>
            <InputLabel id="user-form-select-label">Renda familiar</InputLabel>
            <Select
              labelId="user-form-select-label"
              id="user-form-select"
              value={formData.familyIncome}
              onChange={(ev) => {
                setFormData({ ...formData, familyIncome: ev.target.value });
              }}
              required={true}
              displayEmpty
              renderValue={(value) => value || "Selecione um valor"}
              fullWidth
            >
              <MenuItem value={"Até 1.5 salários minimos (até R$ 1.412)"}>
                Até 1.5 salários minimos (até R$ 1.412)
              </MenuItem>
              <MenuItem value={"de 1.5 até 3 salários minimos (de R$ 1.412 a R$ 4.236)"}>
                de 1.5 até 3 salários minimos (de R$ 1.412 a R$ 4.236)
              </MenuItem>
              <MenuItem value={"3 a 5 salários minimos (de R$ 4.236 a R$ 7.060)"}>
                3 a 5 salários minimos (de R$ 4.236 a R$ 7.060)
              </MenuItem>
              <MenuItem value={"Mais de 5 salários minimos (acima de R$ 7.060)"}>
                Mais de 5 salários minimos (acima de R$ 7.060)
              </MenuItem>
            </Select>
          </MDBox>
          <MDBox mt={4}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                prevHandler({ ...formData });
              }}
              fullWidth
            >
              Anterior
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mt={2} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              nextHandler({ ...formData });
            }}
            fullWidth
          >
            Seguinte
          </MDButton>
        </MDBox>

        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              save({ ...formData }, true, setLoading);
            }}
            fullWidth
          >
            {loading ? <CircularProgress /> : <b>Salvar e ir para Hine</b>}
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ParentUserForm;

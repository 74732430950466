import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import PageLayout from "examples/LayoutContainers/PageLayout";

import { Link, useNavigate } from "react-router-dom";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import env from "react-dotenv";

import axios from "axios";

import { setCurrentPatient, setCurrentUuid, setPatients } from "../../reducers";

import { v4 as uuidv4 } from "uuid";

import { Box } from "@mui/material";
import logo_fapeam from "./fapeam.png";
import logo_hine from "./hine.png";
import logo_labtam from "./labtam.png";
import logo_ufam from "./ufam.png";
import { Container } from "@material-ui/core";

function MainMenu() {
  const hineData = useSelector((state) => state.hine.data);
  const disp = useDispatch();
  const [patients, setPatientsState] = useState([]);
  const [recordCount, setRecordCount] = useState(0);
  const authData = useSelector((state) => state.auth.data);
  const navigate = useNavigate();

  useEffect(() => {
    disp(setCurrentUuid(uuidv4()));
  }, [disp]);

  useEffect(() => {
    if (!authData.logedIn || !authData.token) {
      navigate("/");
    }
  }, [authData, navigate]);

  useEffect(() => {
    const url = `${env.API_URL}/admin/patient/getByDoctor/${authData.userId}?secret_token=${authData.token}`;
    axios.get(url).then((resp) => {
      disp(setPatients(resp.data));
    });

    const recordUrl = `${env.API_URL}/admin/record/getByDoctorCount/${authData.userId}?secret_token=${authData.token}`;
    axios.get(recordUrl).then((resp) => {
      setRecordCount(resp.data);
    });

    disp(setCurrentPatient(null));
  }, [authData.token, authData.userId, disp]);

  useEffect(() => {
    setPatientsState([...Object.keys(hineData)]);
  }, [hineData]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <DefaultNavbar
        action={{
          type: "external",
          route: "https://google.com",
          label: "free download",
          color: "dark",
        }}
      />
      <Grid item xs={12} sm={4} display="flex" justifyContent="center">
        <img
          src={logo_hine}
          alt="Logo HINE"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Grid>
      <MDBox component="main" sx={{ flexGrow: 1, overflow: "auto" }}>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
          }}
        >
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to="/user-form">
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Inserir paciente"
                  count={patients.length}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Adicionar paciente",
                  }}
                  onClick
                />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to="/hine-menu">
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Acessar dados de paciente"
                  count="-1"
                  percentage={{
                    color: "success",
                    amount: "-1",
                    label: "comparado con ontem",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to="/records">
                <ComplexStatisticsCard
                  color="warning"
                  icon="history"
                  title="Relatorios"
                  count={recordCount}
                  percentage={{
                    color: "info",
                    amount: "-1",
                    label: "comparado con ontem",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Box component="footer" sx={{ p: 2 }}>
        <Container maxWidth="lg">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={4} display="flex" justifyContent="center">
              <img
                src={logo_ufam}
                alt="Logo UFAM"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
              <img
                src={logo_labtam}
                alt="Logo LABTAM"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
              <img
                src={logo_fapeam}
                alt="Logo FAPEAM"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default MainMenu;

import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import UserForm from "layouts/user-form";
import HineForm from "layouts/hine-form";
import MainMenu from "layouts/main-menu";
import HineMenuPage from "layouts/hine-form/hine-menu-page";
import HineSectionsPage from "layouts/hine-form/hine-sections-menu";
import RecordsMain from "layouts/reports";
import ResetPassword from 'layouts/authentication/reset-password/cover'

// @mui icons
import Icon from "@mui/material/Icon";
import ResetToken from "layouts/authentication/reset-password/reset-token";

export const authRoutes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">reset</Icon>,
    route: "/authentication/reset-password/cover",
    component: <ResetPassword />,
  },
  {
    type: "collapse",
    name: "Reset Token",
    key: "reset-token",
    icon: <Icon fontSize="small">reset</Icon>,
    route: "/authentication/reset-password/reset-token",
    component: <ResetToken />,
  },
];

const routes = [
  {
    type: "collapse",
    name: "Main Menu",
    key: "mainmenu",
    icon: <Icon fontSize="small">mainmenu</Icon>,
    route: "/mainmenu",
    component: <MainMenu />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <MainMenu />,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <MainMenu />,
  },
  {
    type: "collapse",
    name: "User Form",
    key: "user-form",
    icon: <Icon fontSize="small">user-form</Icon>,
    route: "/user-form",
    component: <UserForm />,
  },
  {
    type: "collapse",
    name: "Hine Form",
    key: "hine-form",
    icon: <Icon fontSize="small">hine-form</Icon>,
    route: "/hine-form",
    component: <HineForm />,
  },
  {
    type: "collapse",
    name: "Sec Buttons Page",
    key: "hine-sections-menu",
    icon: <Icon fontSize="small">hine-sec-buttons</Icon>,
    route: "/hine-sections-menu",
    component: <HineSectionsPage />,
  },
  {
    type: "collapse",
    name: "Section One Menu",
    key: "hine-menu",
    icon: <Icon fontSize="small">hine-secone-menu</Icon>,
    route: "/hine-menu",
    component: <HineMenuPage />,
  },
  {
    type: "collapse",
    name: "Records",
    key: "record",
    icon: <Icon fontSize="small">record</Icon>,
    route: "/records",
    component: <RecordsMain />,
  },
];

export default routes;

import { useState, useEffect, useMemo } from "react";
import env from "react-dotenv";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import axios from "axios";
import routes from "routes";
import { authRoutes } from "routes";
import theme from "assets/theme";
import { ThemeProvider, CssBaseline } from "@mui/material";

export default function App() {
  const authData = useSelector((state) => state.auth.data);
  const VerifiedState = useMemo(() => {
    return {
      Pending: 0,
      Verified: 1,
      Forbbiden: 2,
    };
  }, []);

  const [verified, setVerified] = useState(VerifiedState.Forbbiden);

  useEffect(() => {
    console.log("token in App.js", authData.token);
    if (authData.token) {
      axios
        .get(`${env.API_URL}/admin/profile?secret_token=${authData.token}`)
        .then((resp) => {
          console.log("got token", resp);
          setVerified(
            resp.status === 200 || resp.status === 201
              ? VerifiedState.Verified
              : VerifiedState.Forbbiden
          );
        })
        .catch((e) => setVerified(VerifiedState.Forbbiden));
    } else {
      setVerified(VerifiedState.Forbbiden);
    }
  }, [authData, VerifiedState]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  if (verified === VerifiedState.Verified) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/mainmenu" />} />
        </Routes>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(authRoutes)}
        <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
      </Routes>
    </ThemeProvider>
  );
}

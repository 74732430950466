import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import { useDispatch } from "react-redux";
import { clear } from "../../../reducers/auth";
import { clearHine } from "../../../reducers";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";

function DefaultNavbarMobile({ open, close }) {
  const { width } = open && open.getBoundingClientRect();
  const disp = useDispatch();
  const navigate = useNavigate();

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <MDBox px={0.5}>
        <MDBox
          mx={1}
          p={1}
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer", userSelect: "none" }}
          onClick={() => {
            disp(clear());
            disp(clearHine());
            setTimeout(() => navigate("/"), 100);
          }}
        >
          <Icon
            sx={{
              color: ({ palette: { white, secondary } }) => secondary.main,
              verticalAlign: "middle",
            }}
          >
            logout
          </Icon>
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="dark"
            textTransform="capitalize"
            sx={{ width: "100%", lineHeight: 0 }}
          >
            Log out
          </MDTypography>
        </MDBox>
      </MDBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object])
    .isRequired,
};

export default DefaultNavbarMobile;

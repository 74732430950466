import { configureStore } from "@reduxjs/toolkit";
import hineReducer from "./reducers";
import patientsReducer from "./reducers/patients";
import authReducer from "./reducers/auth";

export default configureStore({
  reducer: {
    hine: hineReducer,
    patients: patientsReducer,
    auth: authReducer,
  },
});

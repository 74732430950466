/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "../../../main-menu/bg.jpeg";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import env from "react-dotenv";
import { clear } from "reducers/auth";
import { Modal } from "antd";

function ResetToken() {
  const [data, setData] = useState({ senha: "", repSenha: "" });
  const navigate = useNavigate();
  const customErrorMessage = "Erro ao resetar, tente mais tarde";
  const [showError, setShowError] = useState(false);
  const disp = useDispatch();
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");

  const setPassword = async () => {
    const url = `${env.API_URL}/auth/reset-token`;
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('token');

    return axios
      .post(url, { senha: data.senha, token: myParam })
      .then((resetResp) => {
        return resetResp.data
      })
      .catch((err) => {
        disp(clear());
        setShowError(true);
        return { message: err.message }
      });
  };

  const setSenha = async () => {
    if(data.senha !== data.repSenha) {
        alert("As senha não são iguais")
    } else {
        setMessage((await setPassword()).message)
        setMessage("Senha alterada com sucesso.")
        setModal(true);
    }
  }

  return (
    <BasicLayout image={bgImage}>
      <Modal
        open={modal}
        onCancel={() => {
          setModal(false);
          navigate("/")
        }}
        onClose={() => {
          setModal(false);
          navigate("/")
        }}
        onOk={() => {
          setModal(false);
          navigate("/")
        }}
      >
        <MDBox>
          <MDTypography>
            {message}
          </MDTypography>
        </MDBox>
      </Modal>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Resete sua senha
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Insira sua nova senha
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput onChange={(evt) => setData({ ...data, senha: evt.target.value })} type="password" label="Senha" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={4}>
              <MDInput onChange={(evt) => setData({ ...data, repSenha: evt.target.value })} type="password" label="Repita a senha" variant="standard" fullWidth />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton onClick={setSenha} variant="gradient" color="info" fullWidth>
                Setar nova senha
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetToken;

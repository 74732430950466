import MainUserForm from "./main";
import ParentUserForm from "./parent";
import ResidenceUserForm from "./residence";
import ClinicalDataUserForm from "./clinical-data";

import PageLayout from "examples/LayoutContainers/PageLayout";

import Slide from "@mui/material/Slide";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import MDBox from "components/MDBox";

import React, { useState, useEffect } from "react";
import axios from "axios";

import env from "react-dotenv";
import { useSelector, useDispatch } from "react-redux";

import { setPatients, setCurrentPatient } from "../../reducers";
import { useNavigate } from "react-router-dom";

const ShowFormEnum = {
  Main: 0,
  Parents: 1,
  Residence: 2,
  ClinicalData: 3,
};

function Basic() {
  const [currentForm, setCurrentForm] = useState(ShowFormEnum.Main);
  const containerRef = React.useRef(null);
  const authData = useSelector((state) => state.auth.data);

  const disp = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authData.logedIn) {
      navigate("/");
    }
  }, [authData, navigate]);

  const [formData, setFormData] = useState({
    sex: "Masculino",
    name: "",
    birthDate: "",
    forwardedBy: "",
    diagnosis: "",
    checkupDate: "",
    therapist: "",
    academic: "",
    fatherName: "",
    fatherProfission: "",
    fatherSchollarship: "",
    motherName: "",
    motherProfission: "",
    motherSchollarship: "",
    familyIncome: "",
    address: "",
    phone: "",
    cpf: "",
    rg: "",
    idadeGestacional: "",
    idadeCronologica: "",
    idadeCorrigida: "",
  });

  const save = (data, redirect = false, setLoading = null) => {
    const allData = { ...formData, ...data, doctor: authData.userId };
    const url = `${env.API_URL}/admin/patient?secret_token=${authData.token}`;
    if (setLoading) setLoading(true);
    axios.post(url, allData).then((resp) => {
      disp(setCurrentPatient(resp.data));
      const patientUrl = `${env.API_URL}/admin/patient/getByDoctor/${authData.userId}?secret_token=${authData.token}`;
      axios
        .get(patientUrl)
        .then((resp) => {
          disp(setPatients(resp.data));
          if (redirect) {
            navigate("/hine-menu");
          }
        })
        .finally(() => {
          if (setLoading) setLoading(false);
        });
    });
  };

  return (
    <PageLayout>
      <DefaultNavbar
        action={{
          type: "external",
          route: "https://google.com",
          label: "free download",
          color: "dark",
        }}
      />

      <MDBox
        sx={{ position: "relative", marginTop: 1 }}
        alignItems="center"
        display="flex"
        justifyContent="center"
        fullWidth
      >
        <Slide
          direction="left"
          in={currentForm === ShowFormEnum.Main}
          container={containerRef.current}
          sx={{
            position: "absolute",
            top: 0,
            margin: "auto",
          }}
          width="90%"
          fullWidth
        >
          <MDBox fullWidth>
            <MainUserForm
              nextHandler={(newFormData) => {
                setFormData({ ...formData, ...newFormData });
                setCurrentForm(ShowFormEnum.Parents);
              }}
              save={save}
            />
          </MDBox>
        </Slide>
        <Slide
          direction="left"
          in={currentForm === ShowFormEnum.Parents}
          container={containerRef.current}
          sx={{
            position: "absolute",
            top: 0,
          }}
          width="90%"
        >
          <MDBox>
            <ParentUserForm
              nextHandler={(newFormData) => {
                setFormData({ ...formData, ...newFormData });
                setCurrentForm(ShowFormEnum.Residence);
              }}
              prevHandler={(newFormData) => {
                setFormData({ ...formData, ...newFormData });
                setCurrentForm(ShowFormEnum.Main);
              }}
              save={save}
            />
          </MDBox>
        </Slide>
        <Slide
          direction="left"
          in={currentForm === ShowFormEnum.Residence}
          container={containerRef.current}
          sx={{ position: "absolute", top: 0 }}
          width="90%"
        >
          <MDBox>
            <ResidenceUserForm
              nextHandler={(newFormData) => {
                setFormData({ ...formData, ...newFormData });
                setCurrentForm(ShowFormEnum.ClinicalData);
              }}
              prevHandler={(newFormData) => {
                setFormData({ ...formData, ...newFormData });
                setCurrentForm(ShowFormEnum.Parents);
              }}
              save={save}
            />
          </MDBox>
        </Slide>
        <Slide
          direction="left"
          in={currentForm === ShowFormEnum.ClinicalData}
          container={containerRef.current}
          sx={{ position: "absolute", top: 0 }}
          width="90%"
        >
          <MDBox>
            <ClinicalDataUserForm
              nextHandler={(newFormData) => {
                setFormData({ ...formData, ...newFormData });
              }}
              prevHandler={(newFormData) => {
                setFormData({ ...formData, ...newFormData });
                setCurrentForm(ShowFormEnum.Residence);
              }}
              save={save}
            />
          </MDBox>
        </Slide>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;
